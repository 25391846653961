import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faker } from '@faker-js/faker';

import {
  Container,
  Card,
  Stack,
  Divider,
  TextField,
  Typography,
  Grid,
  TableContainer,
  ListItemIcon,
  Button
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import Iconify from '../../Iconify';
import { useGetInterviewLatestQuery } from '../../../redux/services/interview/InterviewServices';

function LatestInterview() {
  const navigate = useNavigate();
  const { data, refetch, error } = useGetInterviewLatestQuery();

  useEffect(() => {
    refetch();
  }, [])

  const interviewData = data?.data || [];

  const columns = [
    {
      name: 'view',
      label: 'View',
      options: {
        // display: interviewColumnVisibility.view,
        filter: true,
        sort: false,
        setCellProps: () => ({ 'data-label': 'View' }),
        customBodyRenderLite: (dataIndex) => (
          <>
            <Button
              style={{ minWidth: 0, marginRight: '5px' }}
              onClick={() => {
                // navigate(`/dashboard/candidate/perticularCandidate/${data?.list[dataIndex]?.id}`);
                navigate(`/dashboard/interviews/perticularInterview/${interviewData[dataIndex]?.id}`);
              }}
              color="info"
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="carbon:view-filled" width={24} height={24} sx={{ color: '#C2CFE0' }} />
              </ListItemIcon>
            </Button>
          </>
        ),
      },
    },
    {
      name: 'id',
      label: 'Interview Id',
      options: {
        filter: true,
        sort: true,
        // display: interviewColumnVisibility.id,
        setCellProps: () => ({ 'data-label': 'Interview Id' }),
      },
    },
    {
      name: 'title',
      label: 'Interview Name',
      options: {
        // display: interviewColumnVisibility.title,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Interview Name' }),
        customBodyRender: (value, tableMeta) => {
          const dataIndex = tableMeta.rowIndex;  
          const interviewId = tableMeta.currentTableData[dataIndex]?.data[1];; 
          return (
            <Button
              onClick={() => navigate(`/dashboard/interviews/perticularInterview/${interviewId}`)}
              sx={{
                color: 'primary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {value}
            </Button>
          );
        },
      },
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        // display: interviewColumnVisibility.date,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Date' }),
      },
    },
    {
      name: 'time_start',
      label: 'Time Start',
      options: {
        // display: interviewColumnVisibility.time_start,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Time Start' }),
      },
    },
    {
      name: 'time_end',
      label: 'Time End',
      options: {
        // display: interviewColumnVisibility.time_end,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Time End' }),
      },
    },
    {
      name: 'job',
      label: 'Job Title',
      options: {
        filter: true,
        sort: true,
        // display: interviewColumnVisibility.job,
        setCellProps: () => ({ 'data-label': 'Job Title' }),
        customBodyRender: (value, metaData) => {
          let job = value?.id || '';
          if (job === '' && interviewData[metaData.rowIndex]?.job__id) {
            job = interviewData[metaData.rowIndex].job__id;
          }
          let name = value?.title || '';
          if (name === '' && interviewData[metaData.rowIndex]?.job__title) {
            name = interviewData[metaData.rowIndex].job__title;
          }

          return (
            <Button
              onClick={() => navigate(`/dashboard/candidate/jobDetails/${job}`)}
              sx={{
                color: 'primary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {name}
            </Button>
          );
        },
      },
    },
    {
      name: 'candidate',
      label: 'Candidate Name',
      options: {
        filter: true,
        sort: true,
        // display: interviewColumnVisibility.candidate,
        setCellProps: () => ({ 'data-label': 'Candidate Name' }),
        customBodyRender: (value, metaData) => {
          let candidate = value?.id || '';
          if (candidate === '' && interviewData[metaData.rowIndex]?.candidate__id) {
            candidate = interviewData[metaData.rowIndex].candidate__id;
          }
          let name = value?.first_name || '';
          if (name === '' && interviewData[metaData.rowIndex]?.candidate__first_name) {
            name = interviewData[metaData.rowIndex].candidate__first_name;
          }
          return (
            <Button
              onClick={() => navigate(`/dashboard/candidate/perticularCandidate/${candidate}`)}
              sx={{
                color: 'primary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {name}
            </Button>
          );
        },
      },
    },
    {
      name: 'updated',
      label: 'Modified date and time',
      options: {
        // display: interviewColumnVisibility.updated,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Modified date and time' }),
        customBodyRender: (value) => {
          if (value) {
            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${day}/${month}/${year} ${date.toLocaleTimeString()}`;
            return <div>{formattedDate}</div>;
          }
          return <div>N/A</div>;
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        // display: interviewColumnVisibility.type,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Type' }),
      },
    },
    {
      name: 'company',
      label: 'Company',
      options: {
        // display: interviewColumnVisibility.company,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Company' }),
        customBodyRender: (value, metaData) => {
          const company = value && value.name ? value.name : interviewData[metaData.rowIndex]?.company__name || 'N/A';
          return company;
        },
      },
    },
    {
      name: 'location',
      label: 'Location',
      options: {
        // display: interviewColumnVisibility.location,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Location' }),
        customBodyRender: (value, metaData) => {
          const location = value && value.name ? value.name : interviewData[metaData.rowIndex]?.location__name || 'N/A';
          return location;
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard', // or 'vertical', 'simple'
    filter: true,
    download: true,
    print: true,
    tableBodyHeight: '480px', // Adjust as needed
    tableBodyMaxHeight: '600px', // Adjust as needed
    selectableRows: 'multiple', // Disable row selection if not needed
    rowsPerPage: 5, // Adjust as needed
    rowsPerPageOptions: [], // Adjust as needed
    customToolbarSelect: () => {}, // Customize toolbar if needed
    viewColumns: true,
    textLabels: {
      body: {
        noMatch: "No interviews scheduled at the moment.",
      },
    },
  };

  return (
    <div>
      <Container>
        {/* <h3 sx={{margin:"5%"}}>Recent Interviews Scheduled</h3> */}
        {/* <Container sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom:"5%"
         }}>
          <Stack sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
            <Stack sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between",
             }}>
              <Stack sx={{
                display: "flex",
                flexDirection: "column",
                width:"600px"
               }}>
                <h3>{interviewData[0]?.job_title}</h3>
                <h4>{interviewData[0]?.candidate_name}</h4>
              </Stack>
              <Stack sx={{

              }}>
                <h5>{interviewData[0]?.date}</h5>
              </Stack>
            </Stack>
          </Stack>
        </Container>
        <Container sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom:"5%"
         }}>
          <Stack sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
            <Stack sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between",
             }}>
              <Stack sx={{
                display: "flex",
                flexDirection: "column",
                width:"600px"
               }}>
                <h3>{data?.data[1]?.job_title}</h3>
                <h4>{data?.data[1]?.candidate_name}</h4>
              </Stack>
              <Stack sx={{

              }}>
                <h5>{data?.data[1]?.date}</h5>
              </Stack>
            </Stack>
          </Stack>
        </Container>
        <Container sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom:"5%"
         }}>
          <Stack sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
            <Stack sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between",
             }}>
              <Stack sx={{
                display: "flex",
                flexDirection: "column",
                width:"600px"
               }}>
                <h3>{data?.data[2]?.job_title}</h3>
                <h4>{data?.data[2]?.candidate_name}</h4>
              </Stack>
              <Stack sx={{

              }}>
                <h5>{data?.data[2]?.date}</h5>
              </Stack>
            </Stack>
          </Stack>
        </Container>
        <Container sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom:"5%"
         }}>
          <Stack sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
            <Stack sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between",
             }}>
              <Stack sx={{
                display: "flex",
                flexDirection: "column",
                width:"600px"
               }}>
                <h3>{data?.data[3]?.job_title}</h3>
                <h4>{data?.data[3]?.candidate_name}</h4>
              </Stack>
              <Stack sx={{

              }}>
                <h5>{data?.data[3]?.date}</h5>
              </Stack>
            </Stack>
          </Stack>
        </Container>
        <Container sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom:"5%"
         }}>
          <Stack sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
            <Stack sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between",
             }}>
              <Stack sx={{
                display: "flex",
                flexDirection: "column",
                width:"600px"
               }}>
                <h3>{data?.data[4]?.job_title}</h3>
                <h4>{data?.data[4]?.candidate_name}</h4>
              </Stack>
              <Stack sx={{

              }}>
                <h5>{data?.data[4]?.date}</h5>
              </Stack>
            </Stack>
          </Stack>
        </Container> */}
          <Grid item xs={12}>
            {/* <Card flex={4}> */}
              <TableContainer>
                <MUIDataTable 
                  title={'Recent Interviews Scheduled'} 
                  data={interviewData} 
                  columns={columns} 
                  options={{
                    ...options,
                    responsive: 'standard',
                    scrollY: '400px',
                    scrollX: true,
                }}
                />
              </TableContainer>
            {/* </Card> */}
          </Grid>
          
        {/* {data?.data?.map((e, i) => (
        <Container key={i}>
          <h3>{e>.data?.candidate_name}</h3>
        </Container>
      ))} */}
      </Container>
    </div>
  )
}

export default LatestInterview