import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query/react';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { 
  Card, 
  Box, 
  Stack, 
  Button, 
  TextField, 
  Container, 
  CircularProgress, 
  ListItem, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  Tabs, 
  Tab, 
  Avatar, 
  Divider, 
  Chip, 
  ListItemIcon
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import FileUpload from 'react-material-file-upload';
import { LoadingButton } from '@mui/lab';
// eslint-disable-next-line import/no-unresolved
import ImageDisplay from 'src/components/imagePreview/ImageDisplay';
// eslint-disable-next-line import/no-unresolved
import Modal from "src/components/modal/Modal";
// eslint-disable-next-line import/no-unresolved
import { useGetCompanyInfoQuery, useUpdateCompanyInfoMutation, useUpdateCompanyLogoMutation, useGetCompanyTagsQuery, useGetCareerSiteCompanyDataQuery } from 'src/redux/services/settings/CareerSiteService';
// eslint-disable-next-line import/no-unresolved
import { useGetCountryQuery, useGetStateQuery, useGetCityQuery } from 'src/redux/services/settings/CountryStateCityService';
// eslint-disable-next-line import/no-unresolved
import { useGetTestimonialsQuery, useAddTestimonialMutation } from 'src/redux/services/settings/TestimonialService';
import MUIDataTable from 'mui-datatables';
import Iconify from "../../../components/Iconify";
import { showToast } from '../../../utils/toast';
import AddCompany from './AddCompany';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const CareerSite = () => {
  const [tag, setTag] = useState('');
  const [files, setFiles] = React.useState([]);
  const { data,  refetch } = useGetCompanyInfoQuery();
  const { data: countryData, refetch: refetchCountryData } = useGetCountryQuery();
  const [countryId, setCountryId] = useState(skipToken);
  const [stateId, setStateId] = useState(skipToken);
  const [cityId, setCityId] = useState(skipToken);
  const { data: stateData, refetch: refetchStateData } = useGetStateQuery(countryId !== undefined ? countryId : skipToken);
  const { data: cityData, refetch: refetchCityData } = useGetCityQuery(stateId !== undefined ? stateId : skipToken);
  const { data: companyTagsData } = useGetCompanyTagsQuery();
  const [UpdateCompany, UpdateCompanyInfo] = useUpdateCompanyInfoMutation();
  const { data: testimonialData, refetch: testimonialDataRefetch } = useGetTestimonialsQuery()
  const [UpdateCompanyLogo, UpdateCompanyLogoInfo] = useUpdateCompanyLogoMutation();
  const [companyData, setCompanyData] = useState({
    id: "",
    company: "",
    logo: "",
    website: "",
    address: "",
    landmark: "",
    country_id: "",
    state_id: "",
    city_id: "",
    pincode: "",
    description: "",
    tag: [],
  })
  const [tabIndex, setTabIndex] = useState(0)
  const [tags, setTags] = useState(data?.companies?.tag)
  const [newTagValue, setNewTagValue] = useState("")
  const company = JSON.parse(localStorage.getItem("globalUser"))?.company?.id
  const [modalData, setModalData] = useState({ company })
  const [modalOpen, setModalOpen] = useState(false)
  const [addTestimonial, addTestimonialInfo] = useAddTestimonialMutation()
  const { data: bannerData, refetch: refetchBannerData } = useGetCareerSiteCompanyDataQuery(data?.companies?.id);
  const navigate = useNavigate()  

  useEffect(() => {
    refetch();
    testimonialDataRefetch();
    refetchCountryData();
    refetchStateData();
    refetchCityData();
    refetchBannerData();
  }, [])
  
  useEffect(() => {
    if (data) {
      const response = data?.companies;
      setCompanyData({
        id: response.id,
        company: response.name,
        logo: response.logo,
        website: response.website,
        address: response.address,
        landmark: response.landmark,
        country_id: response.country_id,
        state_id: response.state_id,
        city_id: response.city_id,
        pincode: response.pincode,
        description: response.description,
        tag: response.tag
      });
      setCountryId(data?.companies?.country_id);
      setStateId(data?.companies?.state_id)
      setCityId(data?.companies?.city_id)
    }
  }, [data])

  useEffect(() => {
    if(addTestimonialInfo.isError) {
      showToast("error", "Error occurred while creating testimony")
      resetForm();
      setModalOpen(false)
    }
    if(addTestimonialInfo.isSuccess) {
      showToast("success", "Successfully created testimonials")
      resetForm();
      setModalOpen(false)
    }
  }, [addTestimonialInfo])

  if (UpdateCompanyInfo.isSuccess) {
    showToast("success", "career site updated successfully");
    refetch();
    UpdateCompanyInfo.reset();
  }
  if (UpdateCompanyInfo.isError) {
    showToast("error", UpdateCompanyInfo.error.data.msg);
  }
  if (UpdateCompanyLogoInfo.isSuccess) {
    showToast("success", UpdateCompanyLogoInfo.data.msg);
    refetch();
    UpdateCompanyLogoInfo.reset();
  }
  if (UpdateCompanyLogoInfo.isError) {
    showToast("error", "error while updating logo..");
    UpdateCompanyLogoInfo.reset();
  }

  const updateCareerSite = async () => {
    await UpdateCompany(companyData);
  }

  const companyLogoChangeHandler = async (file) => {
    const formData = new FormData();
    formData.append('logo', file);
    // await UpdateCompanyLogo(formData);
  }

  const onInputChangeHandler = (e) => {
    setCompanyData({ ...companyData, [e.target.name]: e.target.name === "tag"?+e.target.value:e.target.value })
  }

  const hangeChangeTabIndex = (e, newValue) => setTabIndex(newValue)

  if (!data) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Container>
    )
  }
  
  const tabs = [];
  if (data.role === "superuser") {
    tabs.push(<Tab label="Company List" {...a11yProps(0)} />);
    tabs.push(<Tab label="Add Company" {...a11yProps(1)} />);
  }
  if (data.role === "admin") {
    tabs.push(<Tab label="Company Details" {...a11yProps(tabs.length)} />);
  }

  const handleTag = (event) => {
    setTag(event.target.value);
  };

  const columns = [
    { name: "id", label: "ID", options: { display: false } },
    { name: "name", label: "Company Name" },
    { name: "admin_email", label: "Admin Email" },
    { name: "phone", label: "Phone" },
    { name: "address", label: "Address" },
    { name: "city_name", label: "City" },
    { name: "state_name", label: "State" },
    { name: "country_name", label: "Country" },
    { name: "website", label: "Website" },
    { name: "pincode", label: "Pincode" },
    {
      name: "update",
      label: "Update Company",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          
          <Button
          style={{ minWidth: 0 }}
          // type="submit"
          onClick={() => handleUpdateCompany(tableMeta.rowData[0])}
          >
          <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
            <Iconify icon="ep:edit" width={24} height={24} color={'blue'} />
          </ListItemIcon>
        </Button>
          
        ),
      },
    },
    // { name: "description", label: "Description" },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'stacked',
    filter: true,
    download: true,
    print: true,
    selectableRows: true
  }

  const handleUpdateCompany = (companyId) => {
    navigate(`/dashboard/career-site/update/${companyId}`);
  };

  const handleUpdateCompanyDynamicData = (companyId) => {
    navigate(`/dashboard/careersite-dynamic/update/${companyId}`);
  };

  const handleAddCompanyDynamicData = (companyId) => {
    navigate(`/dashboard/careersite-dynamic/create/${companyId}`);
  };

  const handleChangeNewTagValue = (e) => setNewTagValue(e.target.value) 

  const handleAddTag = () => {
    setTags(prev => [...prev,newTagValue])
    setNewTagValue("")
    setCompanyData(prev => ({...prev, tag: tags}))
  }

  const handleDeleteTag = (index) => {
    setTags(prev => {
      const newTags = [...prev]
      newTags?.splice(index, 1)
      return newTags
    })
    setCompanyData(prev => ({...prev, tag: tags}))
  }
  
  const resetForm = () => setModalData({ company })

  const handleChangeModalData = (e) => setModalData(prev => ({...prev, [e.target.name]: e.target.value}))

  const handleCreateTestimonial = async () => {
    await addTestimonial(modalData)
    navigate("/dashboard/career-site")
    setModalOpen(false)  
    testimonialDataRefetch()
  }
  const navigateCancel = () => {
    navigate("/dashboard/InstituteSettings/settings")
}

  const jobUrl = `<iframe src="https://jobs.edjobster.com/jobs/${data?.companies?.id}/${data?.companies?.name}" title="${data?.companies?.name}" width="100% !important" height="100% !important" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`

  // const jobUrl = `<iframe src="https://staging-career.edjobster.com/jobs/${data?.companies?.id}/${data?.companies?.name}" title="${data?.companies?.name}" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`


  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Container
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <div className="backbutton tt-back" style={{display: "flex" }} >
                    <ArrowBackIosIcon onClick={navigateCancel} sx={{
                        cursor: "pointer"
                    }} />
                </div>
                {/* <h1 style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
                    Carrer Site
                </h1> */}
                <Typography 
                  variant="h3" 
                  color="textPrimary" 
                  sx={{ 
                    width: '300px',
                    textDecorationLine: 'underline',
                    backgroundColor: 'transparent !important'
                  }}
                >
                  Carrer Site
                </Typography>
            </Container>
        {/* {tabIndex === 1 && <Button onClick={_ => setModalOpen(true)}>Add Testimony</Button>} */}
      </Stack>
      <Tabs value={tabIndex} onChange={hangeChangeTabIndex} sx={{display: "flex", justifyContent: "center"}}>
        {tabs}
      </Tabs>

      {data.role === "superuser" && (
      <TabPanel value={tabIndex} index={0}>
        {Array.isArray(data?.companies) ? (
          <MUIDataTable
            title="Company List"
            data={data.companies}
            columns={columns}
            options={options}
          />
        ) : (
          <Typography>No data available</Typography>
        )}
      </TabPanel>
      )}

      {data.role === "superuser" && (
      <TabPanel value={tabIndex} index={1}>
        <DialogContent>
          <Card sx={{ minWidth: 275, p: 4 }} variant="outlined">
            <Box sx={{ flexGrow: 1 }}>
              <AddCompany />
            </Box>
          </Card>
        </DialogContent>
      </TabPanel>
      )}

      {data.role === "admin" && (
      <TabPanel value={tabIndex} index={tabs.length - 1}>
        <DialogContent>
          <Card sx={{ minWidth: 275, p: 4 }} variant="outlined">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={10}>
                    <Box style={{ display: 'flex', justifyContent: 'right', flexDirection: 'row', gap: '10px' }}>
                      <LoadingButton variant="contained" onClick={() => handleUpdateCompany(companyData.id)} loading={UpdateCompanyInfo.isLoading}> Update Company</LoadingButton>
                      {bannerData === undefined ? (
                        <LoadingButton variant="contained" onClick={() => handleAddCompanyDynamicData(companyData.id)}>
                          Banner Create
                        </LoadingButton>
                      ) : (
                        <LoadingButton variant="contained" onClick={() => handleUpdateCompanyDynamicData(companyData.id)}>
                          Banner Update
                        </LoadingButton>
                      )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField 
                    type='text' 
                    label="Institute Name"
                    value={companyData.company}
                    variant='outlined'
                    fullWidth
                    inputProps={
                      { readOnly: true, }
                    }
                  />
                </Grid>
                <Grid item xs={12} md={7} sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                  <Stack>
                    <ImageDisplay
                      logo={companyData.logo}
                      // onFileSelectSuccess={(file) => companyLogoChangeHandler(file)}
                      // onFileSelectError={({ error }) => showToast("error", error)}
                    />
                  </Stack>
                  <Stack sx={{}}>
                    <Grid item xs={12} md={12} sx={{ width: "100%", marginBottom: "4%" }}>
                      <TextField 
                        type='text' 
                        label="Institute Website"
                        value={companyData.website}
                        variant='outlined'
                        fullWidth
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ width: "100%", marginBottom: "4%" }}>
                      <TextField 
                        type='text' 
                        label="Institute Address"
                        value={companyData.address}
                        variant='outlined'
                        fullWidth
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ width: "100%", marginBottom: "4%" }}>
                      <TextField 
                        type='text' 
                        label="Address Landmark"
                        value={companyData.landmark}
                        variant='outlined'
                        fullWidth
                        inputProps={
                          { readOnly: true, }
                        }
                      />
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={7}>
                  <FormControl variant="outlined" sx={{ minWidth: '100%' }} disabled>
                    <InputLabel id="select-country">Select Country</InputLabel>
                    <Select
                      labelId="select-country"
                      id="country"
                      value={companyData.country_id}
                      label="Select Country"
                    >
                      {countryData && countryData?.countries?.map((country) => <MenuItem key={country?.id} value={country?.id}>{country?.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={7}>
                  <FormControl variant="outlined" sx={{ minWidth: '100%' }} disabled>
                    <InputLabel id="select-state">Select State</InputLabel>
                    <Select
                      labelId="select-state"
                      id="state"
                      value={companyData.state_id}
                      //   onChange={handleChange}
                      label="Select State"
                    >

                      {stateData && stateData?.states?.map((state) => <MenuItem key={state?.id} value={state?.id}>{state?.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={7}>
                  <FormControl variant="outlined" sx={{ minWidth: '100%' }} disabled>
                    <InputLabel id="select-city">Select City</InputLabel>
                    <Select
                      labelId="select-city"
                      id="city"
                      value={companyData.city_id}
                      label="Select City"
                    >
                      {cityData && cityData?.cities?.map((city) => <MenuItem key={city?.id} value={city?.id}>{city?.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField 
                    type='text' 
                    label="Pincode"
                    value={companyData.pincode}
                    variant='outlined'
                    fullWidth
                    inputProps={
                      { readOnly: true, }
                    }
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField 
                    multiline
                    rows={10}
                    type='text' 
                    label="About the Institute"
                    value={companyData.description}
                    variant='outlined'
                    fullWidth
                    inputProps={
                      { readOnly: true, }
                    }
                  />
                </Grid>
                <Grid item sx={12} md={7}>
                  <Divider flexItem sx={{paddingBottom: "1rem"}}>Company Tags</Divider>
                  <Container
                    sx={{
                      display: "flex",
                      gap: ".5rem",
                      flexWrap: "wrap"
                    }}
                  >
                    {tags?.map((e, i) => (
                      <Chip 
                        key={i} 
                        variant="outlined" 
                        label={e}
                        // onDelete={() => handleDeleteTag(i)}
                        sx={{
                          flex: "0 1 auto"
                        }}
                      />
                    ))}
                  </Container>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField
                      fullWidth
                      label="Career Site URL"
                      value={jobUrl}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(jobUrl);
                              showToast("success", "URL copied to clipboard!");
                            }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        ),
                      }}
                      helperText="This is your public career site URL where candidates can view and apply for jobs"
                    />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </DialogContent>
      </TabPanel>
      )}

      {/* <TabPanel value={tabIndex} index={3}>
        <MUIDataTable
          title="Testimonials"
          data={testimonialData}
          columns={columns}
          options={options}
        />
        <Modal
          handleClose={() => {setModalOpen(false); resetForm()}}
          open={modalOpen}
          handleSubmit={handleCreateTestimonial}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start"
            }}
          >
            <Typography variant="h4">Create Testimonial</Typography>
          </Container>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "2rem",
              minWidth: "100%"
            }}
          >
            <TextField 
              name="name"
              onChange={handleChangeModalData}
              label="Name"
              placeholder="Name"
              required
            />
            <TextField 
              name="email"
              onChange={handleChangeModalData}
              label="Email"
              placeholder="Email"
              required
            />
            <TextField 
              name="designation"
              onChange={handleChangeModalData}
              label="Designation"
              placeholder="Designation"
              required
            />
            <TextField 
              name="testimonials"
              onChange={handleChangeModalData}
              label="Testimony"
              placeholder="Testimony"
              required
              multiline
              rows={6}
              maxRows={10}
            />
          </Container>
        </Modal>
      </TabPanel> */}
    </Container>
  );
};

export default CareerSite;
