import React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { TextField, Stack, Button, Container, Typography, Card, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { showToast } from '../utils/toast';
import { useResetPasswordMutation } from '../redux/services/resetpassword/resetPasswordServices';
import useResponsive from '../hooks/useResponsive';
import Page from '../components/Page';
import Logo from '../components/Logo';

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));
  
  const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(7, 5, 0, 7),
    },
  }));
  
  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
  }));
  
  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams(); // Get the token from the URL
  const [resetPassword] = useResetPasswordMutation();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      try {
        await resetPassword({ token, newPassword: values.newPassword }).unwrap();
        await showToast("success", "Password has been reset successfully!");
        navigate('/auth/login', { replace: true });
      } catch (error) {
        await showToast("error", "Failed to reset password.");
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Reset">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          

          {/* {smUp && (
            <p sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link  component={RouterLink} to="/register" style={{backgroundColor:"#f9fafb"}}>
                Get started
              </Link>
            </p>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="reset" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <h2>
              Reset Password
            </h2>

            <p style={{ color: 'text.secondary', marginBottom: "5%" }}>Enter your details below.</p>

            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                    <TextField
                        fullWidth
                        type="password"
                        label="New Password"
                        {...getFieldProps('newPassword')}
                        error={Boolean(touched.newPassword && errors.newPassword)}
                        helperText={touched.newPassword && errors.newPassword}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        label="Confirm Password"
                        {...getFieldProps('confirmPassword')}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                    />
                    <Button fullWidth size="large" type="submit" variant="contained">
                        Reset Password
                    </Button>
                    </Stack>
                </Form>
             </FormikProvider>

            {!smUp && (
              <p style={{textAlign:"center", marginTop: "3%" }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </p>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}