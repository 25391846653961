import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getCandidateList: build.query({
      query: () => ({
        url: `${apiUrl.candidate}candidate/`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    getCandidateDetails: build.query({
      query: (id) => ({
        url: `${apiUrl.candidate}details/?id=${id}`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    addApplyJob: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidate}apply/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    addCandidateResume: build.mutation({
        query: (data) => ({
          url: `${apiUrl.candidate}resume-parse/`,
          method: 'POST',
          body: data,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
          }
        }),
      }),
    addCandidateDetails: build.mutation({
        query: (data) => ({
          url: `${apiUrl.candidate}create-candidate/`,
          method: 'POST',
          body: data,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
          }
        }),
      }),
    updateCandidate: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidate}applications/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    deleteCandidate: build.mutation({
      query: (id) => ({
        url: `${apiUrl.candidate}applications/?id=${id}`,
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    addCandidate: build.mutation({
      query: (data) => ({
        url: `${apiUrl.createCandidateWithResume}`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      })
    }),
    addCandidateWithResume: build.mutation({
      query: (data) => ({
        url: `${apiUrl.createCandidateWithResume}`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      })
    }),
    assignJob: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidate}assign-job/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    addCandidateDocument: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidate}candidate/upload-document/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),
    deleteCandidateDocument: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidate}candidate/upload-document/`,
        method: 'DELETE',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),
    getApplicants: build.query({
      query: (id) => ({
        url: `${apiUrl.candidate}applicant-get/candidate/${id}`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      })
    }),
    updateCandidateStatus: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidate}update-candidate-pipeline-status/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      })
    }),
    candidateStats: build.query({
      query: (id) => ({
        url: `${apiUrl.candidate}update-candidate-status/?candidate=${id}`,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),
    hiringStage: build.query({
      query: (id) => ({
        url: `${apiUrl.settings}pipeline-stage/?id=${id}`,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),

    addParseResume: build.mutation({
      query: (data) => ({
        url: `${apiUrl.parseResume}`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      })
    }),
    getCandidateById: build.query({
      query: (id) => ({
        url: `${apiUrl.candidate}create-candidates/${id}`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    editCandidate: build.mutation({
      query: ({id,data}) => ({
        url: `${apiUrl.candidate}create-candidates/${id}/`,
        method: 'PUT',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    addMultipleCandidate: build.mutation({
      query: ({data,id}) => ({
        url: `${apiUrl.candidate}multiple-jobs/${id}/`,
        method: 'POST',
        body:{
          job_ids:data
        },
      }),
    }),
    applyCandidateFilter: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidate}custom_query/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    emailSettingSmtpData: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidate}email_setting_smtp/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    getEmailSettingSmtpData: build.query({
      query: () => ({
        url: `${apiUrl.candidate}email_setting_smtp/`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    updateEmailSettingSmtpData: build.mutation({
      query: (data) => ({
        url: `${apiUrl.candidate}email_setting_smtp/${data.id}/`,
        method: 'PUT',
        body: data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    deleteEmailSettingSmtpData: build.mutation({
      query: (id) => ({
        url: `${apiUrl.candidate}email_setting_smtp/${id}/`,
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    getEmailCredentials: build.query({
      query: () => ({
        url: `${apiUrl.settings}email_credentials/`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("globalUser").access}`
        }
      }),
    }),
    overrideExisting: false,
  }),
});

export const {
  useGetCandidateListQuery,
  useAddCandidateDetailsMutation,
  useGetCandidateDetailsQuery,
  useAddApplyJobMutation,
  useAddCandidateResumeMutation,
  useUpdateCandidateMutation,
  useDeleteCandidateMutation,
  useAddCandidateMutation,
  useAddCandidateWithResumeMutation,
  useAssignJobMutation,
  useAddCandidateDocumentMutation,
  useDeleteCandidateDocumentMutation,
  useGetApplicantsQuery,
  useUpdateCandidateStatusMutation,
  useCandidateStatsQuery,
  useHiringStageQuery,
  useAddParseResumeMutation,
  useGetCandidateByIdQuery,
  useEditCandidateMutation,
  useAddMultipleCandidateMutation,
  useApplyCandidateFilterMutation,
  useEmailSettingSmtpDataMutation,
  useGetEmailSettingSmtpDataQuery,
  useUpdateEmailSettingSmtpDataMutation,
  useDeleteEmailSettingSmtpDataMutation,
  useGetEmailCredentialsQuery,
} = extendedApi;
