import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AccordionSummary,
  Typography,
  Accordion,
  AccordionDetails,
  styled,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  // ... existing styles ...
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  // ... existing styles ...
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  // ... existing styles ...
}));

export default function EmailSmtpDocumentationModal({ open, onClose }) {
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle>
          Documentation
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ position: 'absolute', right: 15, top: 8 }} // Position the close button
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height: '400px' }}>
          <StyledAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <StyledAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography component="span">Gmail (Google) & Google Workspace (formerly G Suite)</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                {/* EMAIL_HOST = 'smtp.gmail.com'
                EMAIL_PORT = 587
                EMAIL_USE_TLS = True
                EMAIL_HOST_USER = 'your-email@gmail.com'
                EMAIL_HOST_PASSWORD = 'your-email-password' */}
                <a href="https://www.youtube.com/watch?v=hGoaVus0-Mg&ab_channel=WebTechKnowledge" target="_blank" rel="noopener noreferrer">
                  Gmail auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <StyledAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography component="span">Outlook (Microsoft) & Microsoft 365 (formerly Office 365)</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                {/* EMAIL_HOST = 'smtp.office365.com'
                EMAIL_PORT = 587
                EMAIL_USE_TLS = True 
                EMAIL_HOST_USER = 'your-email@outlook.com'
                EMAIL_HOST_PASSWORD = 'your-email-password' */}
                <a href="https://www.youtube.com/watch?v=5ukSRLRDQIw&ab_channel=OutrightSystems" target="_blank" rel="noopener noreferrer">
                  Microsoft auth password Create
                </a>

              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <StyledAccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography component="span">Yahoo Mail</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                {/* EMAIL_BACKEND = 'django.core.mail.backends.smtp.EmailBackend'
                EMAIL_HOST = 'smtp.mail.yahoo.com'
                EMAIL_PORT = 587
                EMAIL_USE_TLS = True
                EMAIL_HOST_USER = 'your-email@yahoo.com'
                EMAIL_HOST_PASSWORD = 'your-email-password' */}
                <a href="https://www.youtube.com/watch?si=KCRSjOKJMPwPVj22&v=GY4FJW3X77Y&feature=youtu.be" target="_blank" rel="noopener noreferrer">
                  Yahoo auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          {/* <StyledAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <StyledAccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography component="span">iCloud Mail (Apple)</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion> */}
          <StyledAccordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <StyledAccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <Typography component="span">Zoho Mail & Zoho Mail for Business</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                {/* EMAIL_BACKEND = 'django.core.mail.backends.smtp.EmailBackend'
                EMAIL_HOST = 'smtp.zoho.com'
                EMAIL_PORT = 587
                EMAIL_USE_TLS = True
                EMAIL_HOST_USER = 'your-email@zoho.com'
                EMAIL_HOST_PASSWORD = 'your-email-password' */}
                <a href="https://www.youtube.com/watch?v=Uf7o2bjtS1o&ab_channel=Sombex" target="_blank" rel="noopener noreferrer">
                  Zoho auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <StyledAccordionSummary aria-controls="panel5d-content" id="panel5d-header">
              <Typography component="span">SendGrid</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                {/* EMAIL_BACKEND = 'django.core.mail.backends.smtp.EmailBackend'
                EMAIL_HOST = 'smtp.sendgrid.net'
                EMAIL_PORT = 587
                EMAIL_USE_TLS = True
                EMAIL_HOST_USER = 'apikey'  # This is the username for SendGrid
                EMAIL_HOST_PASSWORD = 'your-sendgrid-api-key' */}
                <a href="https://www.youtube.com/watch?si=bw5uSTsRU0Fzt5Si&v=cnxzl6k9Bfo&feature=youtu.be" target="_blank" rel="noopener noreferrer">
                  SendGrid auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>

          <StyledAccordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <StyledAccordionSummary aria-controls="panel6d-content" id="panel6d-header">
              <Typography component="span">Amazon SES</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                {/* EMAIL_BACKEND = 'django.core.mail.backends.smtp.EmailBackend'
                EMAIL_HOST = 'email-smtp.us-east-1.amazonaws.com'  # Change region if necessary
                EMAIL_PORT = 587
                EMAIL_USE_TLS = True
                EMAIL_HOST_USER = 'your-ses-smtp-username'
                EMAIL_HOST_PASSWORD = 'your-ses-smtp-password' */}
              <a href="https://www.youtube.com/watch?si=NfcAP2MfTwa4654Y&v=VWt43MXhuk4&feature=youtu.be" target="_blank" rel="noopener noreferrer">
                  Amazon SES auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
            <StyledAccordionSummary aria-controls="panel7d-content" id="panel7d-header">
              <Typography component="span">Mailgun</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                {/* EMAIL_BACKEND = 'django.core.mail.backends.smtp.EmailBackend'
                EMAIL_HOST = 'email-smtp.us-east-1.amazonaws.com'  # Change region if necessary
                EMAIL_PORT = 587
                EMAIL_USE_TLS = True
                EMAIL_HOST_USER = 'your-ses-smtp-username'
                EMAIL_HOST_PASSWORD = 'your-ses-smtp-password' */}
              <a href="https://www.youtube.com/watch?si=44-SPke90chFO_-e&v=rV-LIgd-Drg&feature=youtu.be" target="_blank" rel="noopener noreferrer">
              Mailgun auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
            <StyledAccordionSummary aria-controls="panel8d-content" id="panel8d-header">
              <Typography component="span">ProtonMail</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
              {/* EMAIL_HOST = '127.0.0.1'
              EMAIL_PORT = 1025
              EMAIL_HOST_USER = 'your-protonmail-email@protonmail.com'
              EMAIL_HOST_PASSWORD = 'your-bridge-generated-password'
              EMAIL_USE_TLS = False
              EMAIL_USE_SSL = False */}
              <a href="https://youtu.be/Iudo9XlMhKk?si=dbYMuEHjm22F-_jT" target="_blank" rel="noopener noreferrer">
              ProtonMail auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
            <StyledAccordionSummary aria-controls="panel9d-content" id="panel9d-header">
              <Typography component="span">Postmark</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
              {/* EMAIL_HOST = 'smtp.postmarkapp.com'
              EMAIL_PORT = 587
              EMAIL_HOST_USER = 'your-postmark-api-token'
              EMAIL_HOST_PASSWORD = 'your-postmark-api-token'
              EMAIL_USE_TLS = True
              EMAIL_USE_SSL = False */}
              <a href="https://youtu.be/HpdfubMKNOw?si=qUF-gGbirYYuVQx8" target="_blank" rel="noopener noreferrer">
              Postmark auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
            <StyledAccordionSummary aria-controls="panel10d-content" id="panel10d-header">
              <Typography component="span">Yandex</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
              {/* EMAIL_HOST = 'smtp.yandex.com'
              EMAIL_PORT = 465
              EMAIL_HOST_USER = 'your-email@yandex.com'
              EMAIL_HOST_PASSWORD = 'your-password'
              EMAIL_USE_TLS = False
              EMAIL_USE_SSL = True */}
              <a href="https://youtu.be/nf7mC8EO8ZI?si=Ka027Zs2_gf_jxBK" target="_blank" rel="noopener noreferrer">
              Yandex auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
            <StyledAccordionSummary aria-controls="panel11d-content" id="panel11d-header">
              <Typography component="span">GMX</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
              {/* EMAIL_HOST = 'mail.gmx.com'
              EMAIL_PORT = 587
              EMAIL_HOST_USER = 'your-email@gmx.com'
              EMAIL_HOST_PASSWORD = 'your-password'
              EMAIL_USE_TLS = True
              EMAIL_USE_SSL = False */}
              <a href="https://support.gmx.com/security/2fa/application-specific-passwords.html" target="_blank" rel="noopener noreferrer">
              GMX auth password Create
                </a>
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
      </DialogContent>
      {/* <DialogActions>
          <Button onClick={handleOpenDocumentation}>Open SMTP Documentation</Button>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSendEmail}>{editData ? 'Update' : 'Send'}</Button>
        </DialogActions> */}
    </Dialog >
    </>
  );
}