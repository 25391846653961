import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import mammoth from 'mammoth';
import DOMPurify from 'dompurify';
import DocViewer from '@cyntler/react-doc-viewer';
import './DocumentViewer.css';

const DocumentViewer = ({ open, onClose, fileUrl, fileType }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [docxContent, setDocxContent] = useState('');

    useEffect(() => {
        if (fileUrl && (fileType === 'docx' || fileType === 'doc')) {
            fetch(fileUrl)
                .then(response => response.arrayBuffer())
                .then(buffer => mammoth.convertToHtml({ arrayBuffer: buffer }))
                .then(result => {
                    const cleanHtml = DOMPurify.sanitize(result.value);
                    setDocxContent(cleanHtml);
                })
                .catch(error => console.error('Error fetching or converting DOCX:', error));
        }
    }, [fileUrl, fileType]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth style={{ marginTop: '20px'}}>
            <DialogTitle>
                Document Viewer
                <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div style={{ height: '500px' }}>
                    {fileType === 'pdf' && (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
                        </Worker>
                    )}
                    {['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv'].includes(fileType) && (
                        <DocViewer documents={[{ uri: fileUrl }]} />
                    )}
                    {['jpg', 'jpeg', 'png', 'gif'].includes(fileType) && (
                        <img src={fileUrl} alt="Document" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DocumentViewer;
