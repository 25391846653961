import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faker } from '@faker-js/faker';

import {
  Container,
  Card,
  Stack,
  Divider,
  TextField,
  Typography,
  Grid,
  TableContainer,
  ListItemIcon,
  Button
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import Iconify from '../../Iconify';
import { useGetAgeJobsCountQuery } from '../../../redux/services/jobs/JobServices';

function AgeofJob() {
  const navigate = useNavigate();
  const { data, refetch, error } = useGetAgeJobsCountQuery();

  useEffect(() => {
    refetch();
  }, [])
  
console.log("data",data?.headers);

  const interviewData = data?.headers.job_details || [];  
  const averages = data?.headers.average || {};

  // Add the average line to the top of the interviewData
  const averageLine = {
    id: 'All Jobs (Avg.)', // Unique identifier for the average row
    title: 'All Jobs (Avg.)',
    no_of_positions: averages.no_of_positions || 0,
    age_of_opened_jobs: averages.age_of_opened_jobs || 0,
    delay: averages.delay || 0,
  };

  const updatedInterviewData = [averageLine, ...interviewData]; // Prepend average line

  const columns = [
    {
      name: 'view',
      label: 'View',
      options: {
        // display: interviewColumnVisibility.view,
        filter: true,
        sort: false,
        setCellProps: () => ({ 'data-label': 'View' }),
        customBodyRenderLite: (dataIndex) => {
          const jobDetails = updatedInterviewData[dataIndex];
          const isAverageRow = jobDetails.id === 'All Jobs (Avg.)'; // Check if it's the average row
          return (
            <>
              <Button
                style={{ minWidth: 0, marginRight: '5px' }}
                onClick={() => {
                  if (!isAverageRow) { // Only navigate if it's not the average row
                    navigate(`/dashboard/candidate/jobDetails/${jobDetails?.id}`);
                  }
                }}
                color="info"
                disabled={isAverageRow} // Disable button if it's the average row
              >
                <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                  <Iconify icon="carbon:view-filled" width={24} height={24} sx={{ color: '#C2CFE0' }} />
                </ListItemIcon>
              </Button>
            </>
          );
        },
      },
    },
    // {
    //   name: 'id',
    //   label: 'Interview Id',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     // display: interviewColumnVisibility.id,
    //     setCellProps: () => ({ 'data-label': 'Interview Id' }),
    //   },
    // },
    {
      name: 'title',
      label: 'Job Opening',
      options: {
        filter: true,
        sort: false,
        setCellProps: (value, tableMeta) => {
          if (tableMeta.rowData && tableMeta.rowData[0] === 'All Jobs (Avg.)') {
            return { style: { fontWeight: 'bold', backgroundColor: '#f0f0f0' } };
          }
          return {};
        },
        customBodyRender: (value, tableMeta) => {
          const jobDetails = updatedInterviewData[tableMeta.rowIndex];
          const isAverageRow = jobDetails.id === 'All Jobs (Avg.)'; // Check if it's the average row
          return isAverageRow ? (
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              {value} {/* Display the title without a button */}
            </Typography>
          ) : (
            <Button
              onClick={() => {
                navigate(`/dashboard/candidate/jobDetails/${jobDetails?.id}`);
              }}
              sx={{
                color: 'primary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {value}
            </Button>
          );
        },
      },
    },
    {
      name: 'no_of_positions',
      label: 'No of positions',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => value < 0 ? (
          <>
          {value}<span style={{ color: 'red' }}>&nbsp;↓</span>
          </> // Show red down arrow for negative values
        ) : value,
        setCellProps: (value, tableMeta) => {
          if (tableMeta.rowData && tableMeta.rowData[0] === 'All Jobs (Avg.)') {
            return { style: { fontWeight: 'bold', backgroundColor: '#f0f0f0' } };
          }
          return {};
        },
      },
    },
    {
      name: 'age_of_opened_jobs',
      label: 'Age of opened jobs',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => value < 0 ? (
          <>
          {value}<span style={{ color: 'red' }}>&nbsp;↓</span>
          </>) : value,
        setCellProps: (value, tableMeta) => {
          if (tableMeta.rowData && tableMeta.rowData[0] === 'All Jobs (Avg.)') {
            return { style: { fontWeight: 'bold', backgroundColor: '#f0f0f0' } };
          }
          return {};
        },
      },
    },
    {
      name: 'delay',
      label: 'Delay [IN DAYS]',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => value < 0 ? (
          <>
          {value}<span style={{ color: 'red' }}>&nbsp;↓</span>
          </>) : value,
        setCellProps: (value, tableMeta) => {
          if (tableMeta.rowData && tableMeta.rowData[0] === 'All Jobs (Avg.)') {
            return { style: { fontWeight: 'bold', backgroundColor: '#f0f0f0' } };
          }
          return {};
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard', // or 'vertical', 'simple'
    filter: true,
    download: true,
    print: true,
    tableBodyHeight: '480px', // Adjust as needed
    tableBodyMaxHeight: '600px', // Adjust as needed
    selectableRows: 'multiple', // Disable row selection if not needed
    rowsPerPage: 10, // Adjust as needed
    rowsPerPageOptions: [], // Adjust as needed
    customToolbarSelect: () => {}, // Customize toolbar if needed
    viewColumns: true,
    textLabels: {
      body: {
        noMatch: "No Jobs available.",
      },
    },
  };

  return (
    <div>
      <Container>
          <Grid item xs={12}>
            {/* <Card flex={4}> */}
              <TableContainer>
                <MUIDataTable 
                  title={'Age of Job'} 
                  data={updatedInterviewData} 
                  columns={columns} 
                  options={{
                    ...options,
                    responsive: 'standard',
                    scrollY: '400px',
                    scrollX: true,
                }}
                />
              </TableContainer>

          </Grid>
          
       
      </Container>
    </div>
  )
}

export default AgeofJob