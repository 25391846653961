import React, { useState, useMemo, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// material
import { Card, Stack, Button, Container, Typography, ListItemIcon, Grid, DialogContent, TableContainer, } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// components
import { formatQuery, QueryBuilder, defaultOperators } from 'react-querybuilder';
import { QueryBuilderAntD } from '@react-querybuilder/antd';
import { AssesmentCategory } from './AssesmentFilterOptions';
import 'react-querybuilder/dist/query-builder.css';
import { sortedDataFn } from '../../../utils/getSortedData';
import { showToast } from '../../../utils/toast';

import MainModuleFilter from '../../../components/main/MainModuleFilter';
import Page from '../../../components/Page';

import Iconify from '../../../components/Iconify';
import {
  useGetAssesmentQuery,
  useDeleteAssesmentMutation,
  useUpdateAssesmentMutation,
  useAddAssesmentMutation,
  useApplyAssesmentFilterMutation
} from '../../../redux/services/main/AssesmentService';
import SelectAssessment from '../jobs/job-stepper-components/SelectAssessment';
import OperatorMappings from '../OperatorMappings';
// mock

const initialQuery = { combinator: 'and',
 rules: [
  { field: 'name', operator: 'contains', value: '' },
 ] 
};

const fields = [
  { name: 'name', label: 'Assesment Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'category_name', label: 'Category Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'owner_name', label: 'Owner Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'created_by', label: 'Created by', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'created', label: 'Date Created', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().date },

];

const addGroupAction = (props) => {
  return null;
};

const Assessments = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { data, refetch } = useGetAssesmentQuery();
  const [filteredData, setFilteredData] = useState(data?.data);
  useEffect(() => {
    refetch();
  },[]);

  useEffect(() =>
    setFilteredData(data?.data)
    , [data?.data])
  const [addAssesment, addAssesmentInfo] = useAddAssesmentMutation();
  const [updateAssesment, updateAssesmentInfo] = useUpdateAssesmentMutation();
  const [deleteAssesment, deleteAssesmentInfo] = useDeleteAssesmentMutation();
  const [applyAssesmentFilter, applyAssesmentFilterInfo] = useApplyAssesmentFilterMutation();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [modalName, setModalName] = useState('add');
  const [btnLoader, setBtnLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const [deleteAssesmentId, setDeleteAssesmentID] = useState()
  const [query, setQuery] = useState(initialQuery);
  const [filterData, setFilterData] = useState({})
  const [simpleFilterForm, setSimpleFilterForm] = useState(true)
  const [advanceFilterForm, setAdvanceFilterForm] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [assessmentColumnVisibility, setAssessmentColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem('columnVisibilityAssessment');
    return savedVisibility ? JSON.parse(savedVisibility) : {
      view: true,
      id: false,
      name: true,
      owner_name: true,
      form: true,
      category_name: true,
      created_by: true,
      created: true,
      updated: true,
      action: true,
    };
  });

  const handleClearFilter = () => {
    setQuery(initialQuery);
    setFilteredData(data?.data); 
    refetch(); 
  };

  const removePercentageInBrackets = (query) => {
    // Use regular expression to match and replace % within brackets
    const modifiedQuery = query.replace(/\(([^)]*)\)/g, (match, content) => {
      // Replace % within the content with an empty string
      const withoutPercentage = content.replace(/%/g, '');
      return `(${withoutPercentage})`;
    });

    return modifiedQuery;
  };

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
  };

  const applyFilter = async () => {
    let formattedQuery = formatQuery(query, 'sql');
  
    // Replace 'and' and 'or' with their uppercase equivalents
    formattedQuery = formattedQuery.replace(/\band\b/g, 'AND');
    formattedQuery = formattedQuery.replace(/\bor\b/g, 'OR');
  
    // Replace specific operators with their required formats
    formattedQuery = formattedQuery.replace(/\bcontains\b/g, 'LIKE');
    formattedQuery = formattedQuery.replace(/\bnotContains\b/g, 'NOT LIKE');
    formattedQuery = formattedQuery.replace(/\bequals\b/g, '=');
    formattedQuery = formattedQuery.replace(/\bnotEqual\b/g, '<>');
    formattedQuery = formattedQuery.replace(/\bstartsWith\b/g, 'StartWith');
    formattedQuery = formattedQuery.replace(/\bendWith\b/g, 'EndWith');
    formattedQuery = formattedQuery.replace(/\bblank\b/g, 'IS NULL');
    formattedQuery = formattedQuery.replace(/\bnotBlank\b/g, 'IS NOT NULL');
    formattedQuery = formattedQuery.replace(/\bgreaterThan\b/g, '>');
    formattedQuery = formattedQuery.replace(/\bgreaterThanOrEqual\b/g, '>=');
    formattedQuery = formattedQuery.replace(/\blessThan\b/g, '<');
    formattedQuery = formattedQuery.replace(/\blessThanOrEqual\b/g, '<=');
    formattedQuery = formattedQuery.replace(/\btrue\b/g, 'TRUE');
    formattedQuery = formattedQuery.replace(/\bfalse\b/g, 'FALSE');
  
    // Add percentage signs for LIKE queries
    formattedQuery = formattedQuery.replace(/LIKE '([^']*)'/g, "LIKE '%$1%'");
    formattedQuery = formattedQuery.replace(/NOT LIKE '([^']*)'/g, "NOT LIKE '%$1%'");
    formattedQuery = formattedQuery.replace(/LIKE '%([^']*)'/g, "LIKE '%$1'"); // For endsWith
    formattedQuery = formattedQuery.replace(/LIKE '([^']*)%'/g, "LIKE '$1%'"); // For startsWith
  
    try {
      const response = await applyAssesmentFilter({ query: formattedQuery });
      setFilteredData(response?.data?.result || []);
    } catch (error) {
      console.error("Error applying filter:", error);
    }
  };


  const [addValue, setAddvalue] = useState({ name: '' });
  const [editValue, setEditvalue] = useState({ name: '' });

  const modalHandleClose = () => {
    setModalOpen(false);
    setEditModalOpen(false);
  };
  const navigateCancel = () => {
    // navigate('/dashboard/app');
    navigate(-1);
  };

  const handleClosee = () => {
    setOpen(false);
  };

  const [active, setActive] = React.useState(false);

  const handleeClosee = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setActive(false);
  };
  // console.log('data is fetching form assesment', filteredData);
  const sortData = useMemo(() => {
    const sortresult = sortedDataFn(filteredData);
    return sortresult;
  }, [filteredData]);
  // console.log('data of assesment', filteredData);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // Add Assesment handler
  const addAssesmentHandler = (e) => {
    setAddvalue({ [e.target.name]: e.target.value });
  };
  const addClickHandler = async () => {
    setBtnLoader(true);
    if (modalName === 'Add') {
      // console.log('addValue :', addValue);
      await addAssesment(addValue);
      modalHandleClose(false);
    }
    if (modalName === 'Edit') {
      await updateAssesment(editValue);
      modalHandleClose(false);
    } else {
      console.log(modalName);
    }
  };
  const handleClickOpen = (id) => {
    setDeleteAssesmentID(id)
    setOpen(true);
  };
  // Edit Assesment Handler
  const editAssesmentHandler = (e) => {
    setEditvalue({ ...editValue, [e.target.name]: e.target.value });
  };

  const handleFilter = (data) => {
    setFilteredData(data)
  }

  useEffect(() => {
    if (addAssesmentInfo.isSuccess) {
      setModalOpen(false);
      refetch();
      showToast('success', 'Assesments successfully added.');
      setBtnLoader(false);
      addAssesmentInfo.reset();
      setAddvalue({ name: '' });
    }
    if (addAssesmentInfo.isError) {
      showToast('error', addAssesmentInfo.error.data.msg);
      setBtnLoader(false);
      addAssesmentInfo.reset();
    }
    if (updateAssesmentInfo.isSuccess) {
      setModalOpen(false);
      showToast('success', 'Assessments Category successfully edited.');
      refetch();
      setBtnLoader(false);
      addAssesmentInfo.reset();
    }
    if (updateAssesmentInfo.isError) {
      showToast('error', updateAssesmentInfo.error.data.msg);
      setBtnLoader(false);
      updateAssesmentInfo.reset();
    }
  }, [modalOpen, addAssesmentInfo, setModalOpen, refetch, setBtnLoader, addValue, setAddvalue, updateAssesmentInfo]);

  // Delete Assesment handler
  const onDeletAssesmenteHandler = async (dataIndex, id) => {
    setActive(true);
    setOpen(false);
    setCurrentIndex(dataIndex);
    const dataArr = sortData;
    const currentDataObj = dataArr[dataIndex];
    await deleteAssesment(id);
  };
  useEffect(() => {
    refetch();
  }, [refetch]);
  useEffect(() => {
    if (deleteAssesmentInfo.isSuccess) {
      showToast('success', deleteAssesmentInfo.data.msg);
      deleteAssesmentInfo.reset();
      refetch();
    }
    if (deleteAssesmentInfo.isError) {
      showToast('error', deleteAssesmentInfo.error.data.msg);
      deleteAssesmentInfo.reset();
      refetch();
    }
  }, [deleteAssesmentInfo, refetch]);

  const showAdvanceFilterForm = () => {
    setAdvanceFilterForm(true)
    setSimpleFilterForm(false)
  }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const columns = [
    {
      name: 'view',
      label: 'View',
      options: {
        display: assessmentColumnVisibility.view,
        filter: false,
        sort: false,
        setCellProps: () => ({ 'data-label': 'View' }),
        // customHeadRender: (columnMeta) => (
        //   <th className="state-header" style={{ color: '#334D6E', opacity: '0.5' }}>{columnMeta.label}</th>
        // ),
        customBodyRenderLite: (dataIndex) => (
          <>
            <Button
              style={{ minWidth: 0, marginRight: '5px' }}
              onClick={() => navigate(`/dashboard/assessments/assessment-preview`, { state: {...filteredData?.[dataIndex], categoryName: filteredData?.[dataIndex].category_name  }})}
              color="info"
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="carbon:view-filled" width={24} height={24} sx={{ color: '#C2CFE0' }} />
              </ListItemIcon>
            </Button>
          </>
        ),
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        display: assessmentColumnVisibility.id,
        setCellProps: () => ({ 'data-label': 'ID' }),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: assessmentColumnVisibility.name,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Name' }),
        customBodyRender: (value, tableMeta) => {
          // Find the row data using ID instead of relying on rowIndex
          const id = tableMeta.rowData?.[1]; // Assuming ID is at column index 1
          const rowData = filteredData?.find(item => item.id === id); 
    
          return (
            <Button
              onClick={() => {
                if (rowData) {
                  navigate(`/dashboard/assessments/assessment-preview`, { 
                    state: { ...rowData, categoryName: rowData.category_name } 
                  });
                } else {
                  console.error("Error: Correct row data not found for ID:", id);
                }
              }}
              sx={{
                color: 'primary',
                textTransform: 'none',
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              {value}
            </Button>
          );
        },
      },
    },
    
    {
      name: 'owner_name',
      label: 'Owner',
      options: {
        display: assessmentColumnVisibility.owner_name,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Owner' }),
      
        customBodyRender: (value, metaData) => {
          
          const owner = value || (filteredData && filteredData[metaData.rowIndex]?.company__admin__username) || 'N/A';
          
          return owner;
        },
      },
    },
    {
      name: 'form',
      label: 'Questions',
      options: {
        display: assessmentColumnVisibility.form,
        filter: true,
        sort: false,
        setCellProps: () => ({ 'data-label': 'Questions' }),
        // customHeadRender: (columnMeta) => (
        //   <th className="state-header" style={{ color: '#334D6E', opacity: '0.5' }}>{columnMeta.label}</th>
        // ),
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{value?.length}</div>
        ),
      },
    },
    {
      name: 'category_name',
      label: 'Category',
      options: {
        display: assessmentColumnVisibility.category_name,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Category' }),
        customBodyRender: (value, metaData) => {
          
          const category = value || (filteredData && filteredData[metaData.rowIndex]?.category__name) || 'N/A';
          
          return category;
        },
      },
    },
    {
      name: 'created_by',
      label: 'Created by',
      options: {
        display: assessmentColumnVisibility.created_by,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Category' }),
        customBodyRender: (value, metaData) => {
          
          const created = value || (filteredData && filteredData[metaData.rowIndex]?.created_by__first_name) || '';
          
          return created;
        },
      },
    },
    {
      name: 'created',
      label: 'Date Created',
      options: {
        display: assessmentColumnVisibility.created,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Date Created' }),
        // customHeadRender: (columnMeta) => (
        //   <th className="state-header" style={{ color: '#334D6E', opacity: '0.5' }}>{columnMeta.label}</th>
        // ),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value && typeof value === 'string') {
            const originalDate = new Date(value);
            const formattedDate = `${(originalDate.getMonth() + 1).toString().padStart(2, '0')}/${originalDate.getDate().toString().padStart(2, '0')}/${originalDate.getFullYear()}`;
            return (
              <div>
                {formattedDate}
              </div>
            );
          }
          return (
            <div>
              { }
            </div>
          );
        },
      },
    },
    {
      name: 'updated',
      label: 'Modified date and time',
      options: {
        display: assessmentColumnVisibility.updated,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Modified date and time' }),
        customBodyRender: (value) => {
          if (value) {
            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${day}/${month}/${year} ${date.toLocaleTimeString()}`;
            return <div>{formattedDate}</div>;
          }
          return <div>N/A</div>;
        },
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        display: assessmentColumnVisibility.action,
        filter: false,
        sort: false,
        setCellProps: () => ({ 'data-label': 'Action' }),
        // customHeadRender: (columnMeta) => (
        //   <th className="state-header" style={{ color: '#334D6E', opacity: '0.5' }}>{columnMeta.label}</th>
        // ),
        customBodyRenderLite: (dataIndex) => (
          <>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleeClosee}
              sx={{ height: '10vh' }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                position: 'absolute',
              }}
            >
              <Alert onClose={handleeClosee} severity="success" sx={{ width: '100%' }}>
                Interview Deleted!
              </Alert>
            </Snackbar>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClosee}
                aria-labelledby="responsive-dialog-title"
                sx={{ '& .MuiDialog-paper': { height: '200px'} }}
              >
                <DialogTitle variant="h4">{'Delete'}</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you wan&#39;t to delete this assessment?</DialogContentText>
                  {/* <Button autoFocus onClick={handleClosee}>
                    Disagree
                  </Button>
                  <Button
                    onClick={() => onDeletAssesmenteHandler(dataIndex, deleteAssesmentId)}
                    loading={dataIndex === currentIndex ? useDeleteAssesmentMutation.isLoading : false}
                    autoFocus
                  >
                    Agree
                  </Button> */}
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClosee}>
                    Disagree
                  </Button>
                  <Button
                    onClick={() => onDeletAssesmenteHandler(dataIndex, deleteAssesmentId)}
                    loading={dataIndex === currentIndex ? useDeleteAssesmentMutation.isLoading : false}
                    autoFocus
                  >
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Button
              style={{ minWidth: 0 }}
              // variant="contained"
              component={RouterLink}
              to={`/dashboard/assessments/edit-assessment/${filteredData?.[dataIndex].id}`}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="ep:edit" width={24} height={24} sx={{ color: 'blue' }} />
              </ListItemIcon>
            </Button>

            <LoadingButton
              style={{ minWidth: 0, margin: '0px 5px' }}
              // variant="contained"
              color="error"
              onClick={() => handleClickOpen(filteredData?.[dataIndex].id)}
              // onClick={() => onDeletAssesmenteHandler(dataIndex)}
              loading={dataIndex === currentIndex ? useDeleteAssesmentMutation.isLoading : false}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} sx={{ color: 'red' }} />
              </ListItemIcon>
            </LoadingButton>
          </>
        ),
      },
    },
  ];

  useEffect(() => {
    localStorage.setItem('columnVisibilityAssessment', JSON.stringify(assessmentColumnVisibility));
  }, [assessmentColumnVisibility]);

  const options = {
    filterType: 'dropdown',
    responsive: 'stacked',
    filter: true,
    download: true,
    print: true,
    tableBodyHeight: '400px', 
    tableBodyMaxHeight: '600px', 
    selectableRows: 'multiple', 
    rowsPerPage: 10, 
    rowsPerPageOptions: [10, 20, 30], 
    customToolbarSelect: () => {}, 
    viewColumns: true,
    onColumnViewChange: (changedColumn, action) => {
      setAssessmentColumnVisibility((prevVisibility) => ({
        ...prevVisibility,
        [changedColumn]: action === 'add',
      }));
    },
  };

  useEffect(() => {
    const element = document.getElementById("changer");
    const parentElement = element?.parentElement;
    parentElement.style.paddingTop = "90px"
  },[])

  return (
    <Page id="changer" title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="backbutton tt-back" style={{ display: 'flex' }}>
              <ArrowBackIosIcon
                onClick={navigateCancel}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </div>
            {/* <h1 style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>Assessments</h1> */}
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Assessments
            </Typography>
          </Container>
          <Button
            style={{ width: '14%', height: '46px' }}
            variant="contained"
            component={RouterLink}
            to="/dashboard/assessments/create-assessment"
            startIcon={
              <Iconify
                icon="eva:plus-fill"
                sx={{
                  height: '35px',
                }}
              />
            }
          >
            New Assessment
          </Button>
        </Stack>
        <Button onClick={toggleSidebar} startIcon={<Iconify icon={sidebarOpen ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'} />}>
          {sidebarOpen ? 'Hide Filters' : 'Show Filters'}
        </Button>
        <Grid container spacing={2} style={{ padding: 0 }}>
        {sidebarOpen && (
          <Grid item xs={12} md={3}>
            <Card sx={{ padding: 'inherit', height: '100%' }}>
            {/* {advanceFilterForm && ( */}
              <Stack direction="column" spacing={2} sx={{ maxHeight:'600px', overflowY:'auto',  marginRight: '16px' }}>
                <QueryBuilderAntD>
                  <QueryBuilder
                    fields={fields}
                    query={query}
                    controlElements={{
                      addGroupAction
                    }}
                    onQueryChange={handleQueryChange}
                    autoSelectField={false}
                    autoSelectOperator={false}
                    resetOnFieldChange={false} />
                </QueryBuilderAntD>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    sx={{ width: '200px' }}
                    onClick={applyFilter}
                    startIcon={<Iconify icon="bi:filter-square-fill" />}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClearFilter}
                    sx={{ width: '200px'}}
                    startIcon={<Iconify icon="mdi:filter-remove-outline" />}
                  >
                    Clear Filter
                  </Button>
                </Stack>
              </Stack>
            </Card>
            {/* )}

            <Card>
              <MainModuleFilter onFilter={handleFilter} applyAdvanceFilter={showAdvanceFilterForm} simpleFilterForm={simpleFilterForm} />
            </Card> */}
          </Grid>
        )}
          <Grid item xs={12} md={sidebarOpen ? 9 : 12}>
            <Card>
              <TableContainer>
                <MUIDataTable title={'Assessment List'} data={filteredData} columns={columns} 
                  options={{
                    ...options,
                    responsive: 'standard',
                    scrollY: '400px',
                    scrollX: true,
                  }}
                />
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Assessments;
