import { useMemo, useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// material
import {
  Card, Stack, Button, Container,
  Typography, ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import EmailCommunicationModal from '../../../components/Mains/EmailCommunicationModal';
import { showToast } from '../../../utils/toast';
import { useDeleteEmailSettingSmtpDataMutation, useGetEmailSettingSmtpDataQuery } from '../../../redux/services/candidate/CandidateServices';
import DataTableLazyLoading from '../../../components/lazyloading/DataTableLazyLoading';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
// mock

const Categories = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDataIndex, setSelectedDataIndex] = useState(null);
  const { data = [], isLoading, refetch } = useGetEmailSettingSmtpDataQuery();
  const [deleteEmailSettingSmtpData] = useDeleteEmailSettingSmtpDataMutation();
  const [senderMail, setSenderMail] = useState('');
  const [emailBackend, setEmailBackend] = useState('');
  const [emailHost, setEmailHost] = useState('');
  const [emailPort, setEmailPort] = useState('');
  const [authPassword, setAuthPassword] = useState('');
  const [btnLoader, setBtnLoader] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const [addValue, setAddvalue] = useState({
    name: '',
  });
  const [editValue, setEditValue] = useState({
    id: undefined,
    name: '',
  });

  const handleOpenEmailModal = () => {
    setEditData(null);
    setEmailModalOpen(true);
    setSenderMail('');
    setAuthPassword('');
    setEmailBackend('');
    setEmailHost('');
    setEmailPort('');
  };

  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
  };

  const handleEditEmailModal = (dataIndex) => {
    setEditData(data[dataIndex]);
    setEmailModalOpen(true);
  };

  const handleDialogOpen = (dataIndex) => {
    setSelectedDataIndex(dataIndex);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedDataIndex(null);
  };

  const handleDeleteConfirm = async () => {
    if (selectedDataIndex !== null) {
      await handleDeleteEmail(selectedDataIndex);
    }
    handleDialogClose();
  };

  const handleDeleteEmail = async (dataIndex) => {
    const emailData = data[dataIndex];
    try {
      await deleteEmailSettingSmtpData(emailData.id).unwrap();
      showToast("success", "Email settings deleted successfully!");
      refetch();
    } catch (error) {
      console.error('Failed to delete email settings:', error);
      showToast("error", "Failed to delete email settings.");
    }
  };

  useEffect(() => {
    refetch();
  }, [modalOpen, setModalOpen, refetch, setBtnLoader, setEditModalOpen]);

    if (isLoading) {
      return <DataTableLazyLoading />;
    }

  const columns = [
    {
      name: 'email_backend',
      label: 'Email Backed',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email_host',
      label: 'Email Host',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email_port',
      label: 'Email Port',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'sender_mail',
      label: 'Mail',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => (
          <>
            <Button style={{ minWidth: 0 }} onClick={() => handleEditEmailModal(dataIndex)}>
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="ep:edit" width={24} height={24} color={'blue'} />
              </ListItemIcon>
            </Button>
            <Button style={{ minWidth: 0, marginLeft: '5px' }} onClick={() => handleDialogOpen(dataIndex)}>
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} color={'red'} />
              </ListItemIcon>
            </Button>
          </>
        ),
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
  };
  
  const navigateCancel = () => {
    navigate("/dashboard/InstituteSettings/settings")
  }
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="backbutton tt-back" style={{ display: 'flex' }}>
              <ArrowBackIosIcon
                onClick={navigateCancel}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </div>
            {/* <h1>Categories</h1> */}
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Email Smtp Setting
            </Typography>
          </Container>
          <Button
           style={{width:"14%",height:"46px" }}
            variant="contained"
            component={RouterLink}
            to="#"
            onClick={handleOpenEmailModal}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Add Smtp
          </Button>
        </Stack>

        <Card>
          <MUIDataTable title={'Email Smtp List'} data={data} columns={columns} options={options} />
          {/* <MUIDataTable title={'Email Smtp List'} /> */}
        </Card>
      </Container>
      <EmailCommunicationModal
        open={emailModalOpen} 
        onClose={handleCloseEmailModal}
        editData={editData}
        refetch={refetch}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }} // Adjust the height as needed
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this emailSmtp?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      
    </Page>
  );
};

export default Categories;
