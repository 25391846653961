import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    forgotPasswordMail: build.mutation({
      query: (email) => ({
        url: `${apiUrl.forgotPasswords}`,
        method: "POST",
        body: { email },
      }),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useForgotPasswordMailMutation } = extendedApi;