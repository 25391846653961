import React, { useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery, Box, Typography, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountPopover from "../../layouts/dashboard/AccountPopover";
import SearchField from "./SearchField";
import edjobster09 from "../../assets/images/edjobster09.png";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("globalUser"))?.account;
  const isMobileOrTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const getLogoWidth = () => {
    if (isMobileOrTablet) return '70px';
    return '120px';
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px 0' }}>
        <NavLink to="/dashboard/app" className="header-logo">
          <img src={edjobster09} alt="logo" style={{ width: getLogoWidth() }} />
        </NavLink>
      </Box>
      <List>
        <ListItem button component={NavLink} to="/dashboard/jobs" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
          <ListItemText primary="Jobs" />
        </ListItem>
        <ListItem button component={NavLink} to="/dashboard/candidates" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
          <ListItemText primary="Candidate" />
        </ListItem>
        <ListItem button component={NavLink} to="/dashboard/interviews" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
          <ListItemText primary="Interviews" />
        </ListItem>
        <ListItem button component={NavLink} to="/dashboard/assessments" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
          <ListItemText primary="Assessments" />
        </ListItem>
        <ListItem button component={NavLink} to="/dashboard/InstituteSettings/settings" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box className="header" sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box className="common-width" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 0px' }}>
        <Box className="header-logo-job" sx={{ display: 'flex', alignItems: 'center' }}>
          <NavLink to="/dashboard/app" className="header-logo">
            <img src={edjobster09} alt="logo" style={{ width: getLogoWidth() }} />
          </NavLink>
        </Box>
        {isMobileOrTablet ? (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box className="header-menu-list" sx={{ display: 'flex', alignItems: 'center', gap: '16px', flexWrap: 'wrap' }}>
            <NavLink to="/dashboard/jobs" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
              Jobs
            </NavLink>
            <NavLink to="/dashboard/candidates" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
              Candidate
            </NavLink>
            <NavLink to="/dashboard/interviews" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
              Interviews
            </NavLink>
            <NavLink to="/dashboard/assessments" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
              Assessments
            </NavLink>
            <NavLink to="/dashboard/InstituteSettings/settings" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
              Settings
            </NavLink>
          </Box>
        )}
        {!isMobileOrTablet && (
          <Box className="Header-lower-bar" sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            {/* <SearchField /> */}
            <AccountPopover />
            <Box className="header-user" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography className="header-name" sx={{ backgroundColor: 'transparent !important' }}>
                {(userData?.first_name || "Test") + (` ${userData?.last_name}` || " ")}
              </Typography>
              <Typography className="header-role" sx={{ backgroundColor: 'transparent !important' }}>
                {userData?.role === 'A' ? 'Admin' : 'User'}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;