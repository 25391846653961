import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Autocomplete, Box, Button, Card, CardContent, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import { useForm } from "react-hook-form";

import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { showToast } from 'src/utils/toast';
import FileUploadComponent from '../../../components/FileUploadComponent';

import { useGetEmailCategoryQuery } from "../../../redux/services/settings/EmailCategoryService";
import { useAddEmailTemplateMutation, useUpdateEmailTemplateMutation, useGetEmailTemplateByIdQuery } from "../../../redux/services/settings/EmailTamplateService";


const modules = {
    toolbar: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
    ]
};

const formats = [
    'font',
    'size',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'align',
    'color', 'background'
];

const state = {
    comments: ''
}

/* eslint-disable no-template-curly-in-string */
const mergeFieldCategories = {
    Candidates: [
        { label: 'First Name', value: '${candidate_first_name}' },
        { label: 'Last Name', value: '${candidate_last_name}' },
        { label: 'Middle Name', value: '${candidate_middle_name}' },
        { label: 'Email', value: '${candidate_email}' },
        { label: 'Mobile', value: '${candidate_mobile}' },
        { label: 'Source', value: '${candidate_source}' },
        { label: 'Alternate Mobile', value: '${candidate_alternate_mobile}' },
        { label: 'Alternate Email', value: '${candidate_alternate_email}' },
        { label: 'Marital Status', value: '${candidate_marital_status}' },
        { label: 'Date of Birth', value: '${candidate_date_of_birth}' },
        { label: 'Gender', value: '${candidate_gender}' },
        { label: 'Age', value: '${candidate_age}' },
        { label: 'Last Applied', value: '${candidate_last_applied}' },
        { label: 'Street', value: '${candidate_street}' },
        { label: 'Country', value: '${candidate_country}' },
        { label: 'State', value: '${candidate_state}' },
        { label: 'City', value: '${candidate_city}' },
        { label: 'Pincode', value: '${candidate_pincode}' },
        { label: 'Headline', value: '${candidate_headline}' },
        { label: 'Summary', value: '${candidate_summary}' },
        { label: 'Highest Qualification', value: '${candidate_highest_qualification}' },
        { label: 'Bachelors Degree', value: '${candidate_bachelors_degree}' },
        { label: 'Professional Degree', value: '${candidate_professional_degree}' },
        { label: 'Job Role', value: '${candidate_job_role}' },
        { label: 'Curriculum Board', value: '${candidate_curriculum_board}' },
        { label: 'Functional Area', value: '${candidate_functional_area}' },
        { label: 'Professional Start Date', value: '${candidate_professional_start_date}' },
        { label: 'Professional End Date', value: '${candidate_professional_end_date}' },
        { label: 'Subjects', value: '${candidate_subjects}' },
        { label: 'Notice Period', value: '${candidate_notice_period}' },
        { label: 'Experience Years', value: '${candidate_exp_years}' },
        { label: 'Experience Months', value: '${candidate_exp_months}' },
        { label: 'Admission Date', value: '${candidate_admission_date}' },
        { label: 'Graduation Date', value: '${candidate_graduation_date}' },
        { label: 'Current Job', value: '${candidate_cur_job}' },
        { label: 'Current Employer', value: '${candidate_cur_employer}' },
        { label: 'Pipeline Stage Status', value: '${candidate_pipeline_stage_status}' },
        { label: 'Pipeline Stage', value: '${candidate_pipeline_stage}' },
        { label: 'Certifications', value: '${candidate_certifications}' },
        { label: 'Functional Area', value: '${candidate_fun_area}' },
        { label: 'Skills', value: '${candidate_skills}' },
        { label: 'User Experiences', value: '${candidate_user_experiences}' },
        { label: 'User Educations', value: '${candidate_user_educations}' },
        { label: 'Assessment Data', value: '${candidate_assessment_data}' },
    ],
    Contacts: [
        { label: 'Name', value: '${contact_name}' },
        { label: 'Mobile', value: '${contact_mobile}' },
        { label: 'Email', value: '${contact_email}' },
    ],
    'Job Openings': [
        { label: 'Job Title', value: '${job_title}' },
        { label: 'Job Description', value: '${job_description}' },
        { label: 'Job Department', value: '${job_department}' },
        { label: 'Job Company', value: '${job_company}' },
        { label: 'Job Vacancies', value: '${job_vacancies}' },
        { label: 'Job Assessment', value: '${job_assessment}' },
        { label: 'Job Type', value: '${job_type}' },
        { label: 'Job Nature', value: '${job_nature}' },
        { label: 'Job Educations', value: '${job_educations}' },
        { label: 'Job Speciality', value: '${job_speciality}' },
        { label: 'JobExperience Min', value: '${job_exp_min}' },
        { label: 'Job Experience Max', value: '${job_exp_max}' },
        { label: 'Salary Min', value: '${job_salary_min}' },
        { label: 'Salary Max', value: '${job_salary_max}' },
        { label: 'Salary Type', value: '${job_salary_type}' },
        { label: 'Currency', value: '${job_currency}' },
        { label: 'Job Location', value: '${job_location}' },
        { label: 'Job Link', value: '${job_job_link}' },
        { label: 'Job Boards', value: '${job_job_boards}' },
        { label: 'Webform', value: '${job_webform}' },
    ],
    Assesment: [
        { label: 'Assessment Name', value: '${assessment_name}' },
        { label: 'Assessment Date', value: '${assessment_date}' },
    ],
    User: [
        { label: 'User Name', value: '${user_username}' },
        { label: 'User First name', value: '${user_first_name}' },
        { label: 'User Last Name', value: '${user_last_name}' },
        { label: 'User Email', value: '${user_email}' },
        { label: 'User Mobile', value: '${user_mobile}' },
        { label: 'User Role', value: '${user_role}' },
        { label: 'User Designation', value: '${user_designation}' },
        { label: 'User Department', value: '${user_department}' },
    ],
    Interview: [
        { label: 'Interview Title', value: '${interview_title}' },
        { label: 'Interview Type', value: '${interview_type}' },
        { label: 'Interview Location', value: '${interview_location}' },
        { label: 'Interview Date', value: '${interview_date}' },
        { label: 'Interview Start Time', value: '${interview_time_start}' },
        { label: 'Interview End Time', value: '${interview_time_end}' },
        { label: 'Interview Interviewers', value: '${interview_interviewers}' },

    ],
};
/* eslint-enable no-template-curly-in-string */


function CreateEmail() {
    const { id } = useParams()
    const navigate = useNavigate()
    const { data: emailTemplateData, refetch: emailTemplateDataRefetch } = useGetEmailTemplateByIdQuery(id)
    useEffect(() => emailTemplateDataRefetch(), [id])
    const { data: emailCategoryData, refetch } = useGetEmailCategoryQuery()
    const [addEmailTemplate, addEmailTemplateInfo] = useAddEmailTemplateMutation()
    const {updateEmailTemplate, updateEmailTemplateInfo} = useUpdateEmailTemplateMutation()
    const navigateCancel = () => {
        navigate("/dashboard/email-templates/templates")
    }
    const [UploadedFileName, setUploadedFileName] = useState("")
    const [Uploaded, setUploaded] = useState(false);

    const initialValues = {
        // from: "",
        // client_name: "",
        name: "",
        subject: "",
        attachment: "",
        category_id: 0,
        message: "",
        type: ""
    }

    // useEffect(() => {
    //   if(id) {
    //     setFormData(emailTemplateData)
    //   }
    
    //   return () => {
    //     setFormData(initialValues)
    //   }
    // }, [emailTemplateData])

    useEffect(() => {
        if (id) {
            emailTemplateDataRefetch();
        }
    }, [id, emailTemplateDataRefetch]);
    
    useEffect(() => {
        if (emailTemplateData && Object.keys(emailTemplateData).length !== 0) {
            setFormData(emailTemplateData);
        } else {
            setFormData(initialValues);
        }
    }, [emailTemplateData]);
    
    const [formData, setFormData] = useState(initialValues)
    const [selectedCategory, setSelectedCategory] = useState('User');
    const [selectedMergeField, setSelectedMergeField] = useState('');

    const handleChangeFormData = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }))
    }
    const handleSubmit = async () => {
        await addEmailTemplate(formData)
    }

    const handleCopyMergeField = () => {
        navigator.clipboard.writeText(selectedMergeField)
        showToast("success", "Merge field copied to clipboard.")
    }

    useEffect(() => {
        if (addEmailTemplateInfo.isError) {
            showToast("error", "Error occurred while adding email template.")
        }
        if (addEmailTemplateInfo.isSuccess) {
            showToast("success", "Successfully added email template.")
            // setFormData(initialValues);
            navigate("/dashboard/email-templates/templates", { replace: true })
        }
    }, [addEmailTemplateInfo, navigate])


    return (
        <Container>
            <Container
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 2
                }}
            >
                <ArrowBackIosIcon onClick={navigateCancel} sx={{ cursor: "pointer", marginRight: 1 }} />
                <Typography variant="h4" component="h1" sx={{ backgroundColor: 'transparent !important' }}>
                    Create Email Template
                </Typography>
            </Container>
            <Card sx={{ marginTop: 2 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12}>
                            <Autocomplete
                                options={Object.keys(mergeFieldCategories)}
                                value={selectedCategory}
                                onChange={(event, newValue) => setSelectedCategory(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Module"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Template Name"
                                name="name"
                                value={formData.name}
                                onChange={(event) => handleChangeFormData(event.target.name, event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Subject"
                                name="subject"
                                value={formData.subject}
                                onChange={(event) => handleChangeFormData(event.target.name, event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="category_id"
                                fullWidth
                                label="Email Category"
                                select
                                onChange={(event) => handleChangeFormData(event.target.name, +event.target.value)}
                                value={formData.category_id}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4 + 8,
                                                width: 250,
                                            },
                                        },
                                    },
                                }}
                            >
                                {emailCategoryData && emailCategoryData.data.map((e, i) => (
                                    <MenuItem key={i} value={e.id}>
                                        {e.name}
                                    </MenuItem>
                                ))}
                                {emailCategoryData?.data?.length === 0 && (
                                    <MenuItem value={''}>Email Category Not Listed</MenuItem>
                                )}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="type"
                                fullWidth
                                label="Template Type"
                                select
                                onChange={(event) => handleChangeFormData(event.target.name, event.target.value)}
                                value={formData.type}
                            >
                                {["Internal", "Candidate"].map((e, i) => (
                                    <MenuItem key={i} value={e[0]}>
                                        {e}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Autocomplete
                                        options={Object.keys(mergeFieldCategories)}
                                        value={selectedCategory}
                                        onChange={(event, newValue) => setSelectedCategory(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Available Merge Fields"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Autocomplete
                                        options={mergeFieldCategories[selectedCategory] || []}
                                        getOptionLabel={(option) => option.label}
                                        value={mergeFieldCategories[selectedCategory]?.find(field => field.value === selectedMergeField) || null}
                                        onChange={(event, newValue) => setSelectedMergeField(newValue ? newValue.value : '')}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Field"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Copy Merge Field Value"
                                        value={selectedMergeField}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <IconButton onClick={handleCopyMergeField}>
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="h2" sx={{ marginTop: 4, marginBottom: 2 }}>
                                Body
                            </Typography>
                            <Box sx={{ minHeight: '200px', height: 'auto' }}>
                                <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    value={formData.message || ''}
                                    onChange={(e) => handleChangeFormData("message", e)}
                                    style={{ width: '100%', height: '280px' }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                                <Button onClick={navigateCancel} sx={{ marginRight: 2 }}>
                                    Cancel
                                </Button>
                                <Button type='submit' onClick={handleSubmit} variant="contained">
                                    Submit
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    )
}

export default CreateEmail