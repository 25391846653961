import { useState } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import { Card, Grid, TextField, InputAdornment, IconButton, Stack, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Iconify from '../../../components/Iconify';
import { showToast } from '../../../utils/toast';
import { useChangePasswordMutation } from  '../../../redux/services/changepassword/changePasswordServices';

function ChangePassword() {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [changePassword] = useChangePasswordMutation();
  
    const ChangePasswordSchema = Yup.object().shape({
      oldpassword: Yup.string().required('Old Password is required'),
      password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
      confirmpassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            oldpassword: '',
            password: '',
            confirmpassword: '',
        },
        validationSchema: ChangePasswordSchema,
        onSubmit: async (values, { setSubmitting }) => {
        try {
            const response = await changePassword({ 
                old_password: values.oldpassword, 
                new_password: values.password 
            }).unwrap();
            showToast('success', response.msg); // Use the response message
        } catch (error) {
            showToast('error', error.data?.msg || 'Failed to change password'); // Use error message if available
        } finally {
            setSubmitting(false);
        }
        },
    });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card sx={{
          position: "relative",
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: '0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%)',
          borderRadius: '16px',
          padding: '40px',
          textAlign: 'center'
        }}>
          <div className="backbutton tt-back">
            <ArrowBackIosIcon onClick={() => window.history.back()} sx={{ cursor: "pointer" }} />
          </div>
          <Stack sx={{
            marginTop: "1%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "2%"
          }}>
            <h1 className='dialogueTitle'>Change Password</h1>
          </Stack>

          <Grid container spacing={3} direction="column" alignItems="center" sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showOldPassword ? 'text' : 'password'}
                label="Old Password"
                {...getFieldProps('oldpassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowOldPassword((prev) => !prev)}>
                        <Iconify icon={showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.oldpassword && errors.oldpassword)}
                helperText={touched.oldpassword && errors.oldpassword}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="New Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirm New Password"
                {...getFieldProps('confirmpassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowConfirmPassword((prev) => !prev)}>
                        <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                helperText={touched.confirmpassword && errors.confirmpassword}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Form>
    </FormikProvider>
  );
}

export default ChangePassword;