import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.mutation({
      query: (passwordData) => ({
        url: `${apiUrl.changePasswords}`,
        method: "POST",
        body: passwordData,
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useChangePasswordMutation } = extendedApi;