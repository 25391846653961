// src/sections/auth/reset-password/ResetPassword.js
import React from 'react';
import { Link as RouterLink, useNavigate} from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { TextField, Stack, Button, Card, Link, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { showToast } from '../utils/toast';
import { useForgotPasswordMailMutation } from '../redux/services/forgotpassword/forgotPasswordServices';
import Page from '../components/Page';
import Logo from '../components/Logo';
import useResponsive from '../hooks/useResponsive';
import edjobster07 from "../assets/images/edjobster07.png"

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


export default function ForgotPassword() {
  const navigate = useNavigate();
  const [forgotPassword] = useForgotPasswordMailMutation();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema:ForgotPasswordSchema,
    onSubmit: async (values) => {
      // Implement your reset password logic here
      try {
        // Assume resetPassword is a function that handles the password reset
        await forgotPassword(values.email).unwrap();
        await showToast("success", "Password reset link sent to your email!");
        navigate('/login', { replace: true });
      } catch (error) {
        // Check if the error response contains the specific message
        if (error?.data?.msg === "Account not found !!!") {
          await showToast("error", "Account not found !!!");
        } else {
          await showToast("error", "Failed to send password reset link.");
        }
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Forgot">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          

          {/* {smUp && (
            <p sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link  component={RouterLink} to="/register" style={{backgroundColor:"#f9fafb"}}>
                Get started
              </Link>
            </p>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="forgot" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <h2>
              Forgot Password
            </h2>

            <p style={{ color: 'text.secondary', marginBottom: "5%" }}>Enter your details below.</p>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="email"
                    type="email"
                    label="Email address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <Button fullWidth size="large" type="submit" variant="contained">
                    Send Link
                  </Button>
                </Stack>
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={() => navigate('/login')} // Add this button for navigation
                >
                  Back to Login
                </Button>
              </Form>
            </FormikProvider>

            {!smUp && (
              <p style={{textAlign:"center", marginTop: "3%" }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </p>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
