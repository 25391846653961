// const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/';
const baseUrl = 'https://api.edjobster.com/';
// const baseUrl = 'https://staging-api.edjobster.com/';

const apiUrl = {
  signIn: `${baseUrl}account/sign-in/`,
  forgotPasswords: `${baseUrl}account/forgot-password/`,
  resetPasswords: `${baseUrl}account/reset-password/`,
  changePasswords: `${baseUrl}account/change-password/`,
  department: `${baseUrl}settings/department/`,
  degree: `${baseUrl}settings/degree/`,
  allCompanies:`${baseUrl}account/get-all-company/`,
  usercompanies:`${baseUrl}account/get-company/`,
  designation: `${baseUrl}settings/designation/`,
  companyInfo: `${baseUrl}account/company-info/`,
  // companyLogo: `${baseUrl}account/company-logo/`,
  country: `${baseUrl}common/countires/`,
  state: `${baseUrl}common/states/?id=`,
  city: `${baseUrl}common/cities/?id=`,
  address: `${baseUrl}settings/location/`,
  testimonials: `${baseUrl}settings/testimonials/`,
  user: `${baseUrl}account/members/`,
  companyUser: `${baseUrl}account/company-members/`,
  userUpdate: `${baseUrl}account/update-account/`,
  userPhoto: `${baseUrl}account/member-photo/`,
  activateUser: `${baseUrl}account/activate-member/`,
  deleteUser: `${baseUrl}account/members/`,
  userRole: `${baseUrl}account/member-role/`,
  stage: `${baseUrl}settings/pipeline-stage/`,
  status: `${baseUrl}settings/pipeline-detalis/`,
  pipeline: `${baseUrl}settings/pipeline/`,
  emailCategory: `${baseUrl}settings/email-category/`,
  emailtamplate: `${baseUrl}settings/email-template/`,
  emailVariables: `${baseUrl}settings/email-field/`,
  webform: `${baseUrl}settings/webform/`,
  fields: `${baseUrl}settings/webform-fields/`,
  assementCategory: `${baseUrl}jobs/assesment-category/`,
  assesment:`${baseUrl}jobs/assesment/`,
  assesmentFilter:`${baseUrl}jobs/assessment_query/`,
  assesmentQuestions: `${baseUrl}jobs/assesment-question/`,
  jobs: `${baseUrl}jobs/`,
  jobApplications: `${baseUrl}jobs/job-candidates/`,  
  candidate:`${baseUrl}candidate/`,
  createCandidate: `${baseUrl}candidate/create-candidate-web/`,
  createCandidateWithResume: `${baseUrl}candidate/create-candidates/`,
  parseResume: `${baseUrl}candidate/resume-parse/`, 
  skill:`${baseUrl}candidate/skill/`,         
  subject:`${baseUrl}candidate/subject-specialization/`,          
  interview:`${baseUrl}interview/schedule/`,
  interviewDetails:`${baseUrl}interview/details/`,
  interviewQuery:`${baseUrl}interview/interviewquery/`,
  jobsInterview:`${baseUrl}interview/JobInterviewDetails/`,
  candidatesInterview:`${baseUrl}interview/candidateInterviewDetails/`,
  latest_interview: `${baseUrl}interview/interview-latest/`,
  location:`${baseUrl}settings/location/`,  
  notes: `${baseUrl}candidate/notes/`,
  job_notes: `${baseUrl}jobs/job-notes/`,
  tasks:`${baseUrl}candidate/task/`,
  addTasks:`${baseUrl}candidate/tasks/`,
  taskOptionsList:`${baseUrl}candidate/tasks-options/`,
  events:`${baseUrl}candidate/events/`,
  eventOptionsList:`${baseUrl}candidate/events-options/`,
  calls:`${baseUrl}candidate/calls/`,
  callOptionsList:`${baseUrl}candidate/calls-options/`,
  addTasksJob:`${baseUrl}jobs/tasks/`,
  taskOptionsListJob:`${baseUrl}jobs/tasks-options/`,
  eventsJob:`${baseUrl}jobs/events/`,
  eventOptionsListJob:`${baseUrl}jobs/events-options/`,
  callsJob:`${baseUrl}jobs/calls/`,
  callOptionsListJob:`${baseUrl}jobs/calls-options/`,
  tasksList:`${baseUrl}candidate/task-by-candidate/`,
  notesType:`${baseUrl}common/note-types/`,
  signUp: `${baseUrl}account/sign-up/`,
  approveUser: `${baseUrl}account/approve/`,
  companyTags: `${baseUrl}common/company-tags/`,
  candidateMail:`${baseUrl}common/candidate-mail/`,
  settings: `${baseUrl}settings/`,
  contacts: `${baseUrl}settings/contacts/`,
  companyAdd:`${baseUrl}account/add-company/`,
  careerSiteCompanyData: `${baseUrl}account/careersite-company-details/`,
  userVerifyAccount: `${baseUrl}account/activate/?token=`,
  candidateParseResume: `${baseUrl}candidate/ParseResume/`,
  emailSettingSmtp: `${baseUrl}candidate/email_setting_smtp/`,
  billingPlan: `${baseUrl}settings/billing-plan/`,
  createOrder: `${baseUrl}settings/create_order/`,
  paymentSuccess: `${baseUrl}settings/payment_success/`,
  currentSubscription: `${baseUrl}settings/current_subscription_plan/`,

};

export { baseUrl, apiUrl };
