import React, { useState, forwardRef, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography, TextField, Select, Autocomplete } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from '../Iconify';



const AssignCandidateJobModel = (props) => {
    // const { open, handleClose, candidateListData, handleChange, value, handleSubmit, name } = props;
    const { open, handleClose, candidates, handleChange, value, handleSubmit, name } = props;
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if (open) {
            setSelectedValue(null); // Reset value when dialog opens
        }
    }, [open]);

    return (
        <>
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Assign a candidate to a job</DialogTitle>
                <DialogContent style={{ width: "600px", padding: "0 2rem", paddingTop: "2rem", paddingBottom: "6rem" }}>
                    <Autocomplete
                        options={Array.isArray(candidates?.list) ? candidates.list : []}
                        getOptionLabel={(option) => option ? `${option.first_name || ''} ${option.last_name || ''}` : ''}
                        renderInput={(params) => (
                            <TextField 
                                {...params}
                                label="Select a Candidate"
                                variant="outlined"
                                fullWidth
                                placeholder='Select a Candidate...'
                            />
                        )}
                        value={selectedValue}
                        onChange={(event, newValue) => {
                            setSelectedValue(newValue);
                            handleChange({ target: { value: newValue ? newValue.id : -1 } });
                        }}
                        ListboxProps={{
                            style: {
                                maxHeight: '150px',
                                overflow: 'auto',
                            },
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        filterOptions={(options, { inputValue }) =>
                            options.filter(option =>
                                `${option.first_name} ${option.last_name}`.toLowerCase().includes(inputValue.toLowerCase())
                            )
                        }
                        renderOption={(props, option) => (
                            <li {...props} key={`${option.id}-${option.first_name}-${option.last_name}`}>
                                {option.first_name} {option.last_name}
                            </li>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {handleSubmit(); handleClose()}} variant="outlined">Add</Button>
                    <Button onClick={handleClose} variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AssignCandidateJobModel