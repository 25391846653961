import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './publish.css';
import { CardContent, Card, Grid, Divider, TextField, Box, Menu, MenuItem, InputLabel ,Container} from '@mui/material';
import ReactQuill from 'react-quill';

const Publish = () => {
  const job = useSelector((state) => state.job.job);
  const jobAddressData = useSelector((state) => state.job.jobAddressData);
  const jobGetuserData = useSelector((state) => state.job.jobGetuserData);

  const selectedAddress = jobAddressData?.data?.find((item) => item.id === job.address);
  const owner = jobGetuserData?.list?.find(item => item.account_id === job.owner.account_id);

  const displayedNames = job.member_names?.map((name, index) => {
    if (index === job.length - 1) {
      return name; // If last item, return name without comma
    }
    return `${name.first_name} ${name.last_name}, `; // Add comma after name for all other items
  });  

  return (
    <>
      <Container>
         <Grid container sx={{ mt: 4 }} spacing={2}>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="Job title"
                  value={job.title}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="Number of vacancies"
                  value={job.vacancies}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="Department"
                  value={job.department_name || job.department}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="Job Owner"
                  value={owner ? `${owner.first_name} ${owner.last_name}` : ''}
                  // value={job.owner}
                  // value={`${job.owner?.first_name} ${job.owner?.last_name}`}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="Team member involed"
                  value={displayedNames}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="Type"
                  value={job.type}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="Job Nature"
                  value={job.nature}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>

              <Grid item md={5} sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      label="Work Experience From"
                      value={job.exp_min}
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Work Experience To"
                      value={job.exp_max}
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="Minimum Education"
                  value={job.education}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      label="Salary From"
                      value={job.salary_min}
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Salary To"
                      value={job.salary_max}
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="Salary unit"
                  value={job.salary_type}
                  name="salary_type"
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="currency "
                  value={job.currency}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="State"
                  value={selectedAddress?.state_name || ''}
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sx={{ mb: 2 }}>
                <TextField
                  label="City"
                  value={selectedAddress?.city_name || ''}
                  name="city_name"
                  inputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={10} sx={{ mb: 2 }}>
                <InputLabel>Job description</InputLabel>
                <div
                  style={{
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '4px',
                    minHeight: '100px',
                    backgroundColor: '#f9f9f9',
                    margin: '5px',
                    maxHeight: '200px', // Set a maximum height
                    overflowY: 'auto',  // Enable vertical scrolling
                    overflowX: 'hidden' // Hide horizontal overflow
                  }}
                  dangerouslySetInnerHTML={{ __html: job?.description }}
                />
              </Grid>
            </Grid>
      </Container>
    </>
  );
};

export default Publish;
