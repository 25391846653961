import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { useGetDailyJobsCountQuery } from "../redux/services/jobs/JobServices";
 
export default function ChartsGraph() {

  const { data: apiData, refetch , error, isLoading } = useGetDailyJobsCountQuery(); 

  useEffect(() => {
    refetch();
  },[])

  const data = apiData ? apiData.counts.map(item => ({
    name: item.date, // Assuming 'date' is a property in the response
    Jobs: item.count, // Use the actual count from the API response
  })) : [];
    
  return (
    <>
    <h3 style={{marginLeft: "10px"}}>Daily Publish Jobs</h3>
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={700}
        height={400}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis/>
      <Tooltip />
      <Legend />
      <Bar dataKey="Jobs" barSize={40} fill="#4a77ff" minPointSize={1} />
    </BarChart>
    </ResponsiveContainer>
    </>
  );
}