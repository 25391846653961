import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    resetPassword: build.mutation({
      query: ({ token, newPassword }) => ({
        url: `${apiUrl.resetPasswords}`, // Ensure this URL is correct
        method: "POST",
        body: { token, password : newPassword },
      }),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useResetPasswordMutation } = extendedApi;