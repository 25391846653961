import { faker } from '@faker-js/faker';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, Card, Avatar, Button } from '@mui/material';
import { useDashStatsQuery } from '../redux/dashboard';
import ToDoApp from '../components/homePage/ToDoApp';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import ChartsGraph from './ChartsGraph';
import LatestInterview from '../components/homePage/InterviewStats/LatestInterview';
import "./Login.css";
import HireVector from "../assets/images/hire-vector.png";
import ViewAllCandidatesModel from "../components/Mains/ViewAllCandidatesModel"
import { useGetDailyJobsCountQuery } from '../redux/services/jobs/JobServices';
import AgeofJob from '../components/homePage/AgeofJobStats/AgeofJob';


// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { id: candidateId } = useParams();
  const userData = JSON.parse(localStorage.getItem("globalUser"))?.account
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.login.auth);
  const { data, refetch } = useDashStatsQuery()
  const { data: dailyJobsData , refetch: dailyJobsRefetch } = useGetDailyJobsCountQuery(); 
  const handleClick = (value, stage, id) => {
    if (value > 0) {
      navigate('/dashboard/candidates/', { state: { ids: id, status: stage } })
    }
  }
  useEffect(() => {
    refetch();
    dailyJobsRefetch();
  }, []);

  const pipelineStage = data?.data?.map((e) => ({
    Applied: e.pipeline_stage_stats?.Applied || 0,
    Associated_Screening: e.pipeline_stage_stats?.Associated_Screening || 0,
    Hired: e.pipeline_stage_stats?.Hired || 0,
    Interview: e.pipeline_stage_stats?.Interview || 0,
    Offered: e.pipeline_stage_stats?.Offered || 0,
    Onboarded: e.pipeline_stage_stats?.Onboarded || 0,
    Shortlisted: e.pipeline_stage_stats?.Shortlisted || 0,
  })) || [];

  const pipelineStageSummedData = pipelineStage.reduce((acc, curr) => {
    Object.keys(curr).forEach(key => {
      acc[key] = (acc[key] || 0) + curr[key];
    });
    return acc;
  }, {});

  const pipelineStagechartdata = Object.keys(pipelineStageSummedData).map((key) => ({
    label: key,
    value: pipelineStageSummedData[key] // Use the value corresponding to the key
  })) || []

  const pipelineStageInterviewchartdata = dailyJobsData?.job_stats?.map((e) => ({
    label: e.job_name,
    value: e.pipeline_stage_stats, // Assuming you want to use a different property for hrs
  })) || [];


  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <h1>
          Hi {(userData?.first_name || "Test") + (` ${userData?.last_name}` || " ")} !, Welcome back
        </h1>
        {/* <div className="container">
          <h1>Stats</h1>
          <div className="row">
            <div className="card">
              <AppWidgetSummary title="Screening" total={data?.data?.pipeline_stage_status_stats?.screening} icon={'material-symbols:laptop-chromebook-outline'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Applied" total={data?.data?.pipeline_stage_status_stats?.applied} color="info" icon={'material-symbols:featured-play-list-rounded'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Shortlisted" total={data?.data?.pipeline_stage_status_stats?.shortlisted} color="warning" icon={'material-symbols:select-check-box'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Interviews" total={data?.data?.pipeline_stage_status_stats?.interview} color="error" icon={'mdi:people'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Offered" total={data?.data?.pipeline_stage_status_stats?.offered} icon={'mdi:offer'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Hired" total={data?.data?.pipeline_stage_status_stats?.hired} color="warning" icon={'material-symbols:work'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="On Boarded" total={data?.data?.pipeline_stage_status_stats?.on_boarded} color="info" icon={'material-symbols:cloud-done-rounded'} />
            </div>
          </div>
        </div> */}
        <Stack className='container'>
          <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingRight: '30px' }}>
              <Grid item xs sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>JOBS</Grid>
              <Grid item xs className='tab-button' sx={{ background: '#D7EAFF !important' }}>Screening</Grid>
              {/* <Grid item xs className='tab-button1' sx={{ background: '#CAFFFB !important' }}>Applied</Grid> */}
              <Grid item xs className='tab-button2' sx={{ background: '#FFD0E9 !important' }}>Shortlisted</Grid>
              <Grid item xs className='tab-button3' sx={{ background: '#FFF3B3 !important' }}>Interviews</Grid>
              <Grid item xs className='tab-button4' sx={{ background: '#CBFFC7 !important' }}>Offered</Grid>
              <Grid item xs className='tab-button4' sx={{ background: '#D7EAFF !important' }}>Hired</Grid>
              <Grid item xs className='tab-button4' sx={{ background: '#CBFFC7 !important' }}>On Board</Grid>
            </Grid>
            <div className='grid-scroller' style={{ height: '400px', overflowY: 'auto' }}>
              <Stack gap={1}>
                {data?.data?.map((item, index) => (
                  <Grid container key={index} sx={{ flexDirection: 'row', alignItems: 'center' }} className='data-div'>
                    <Grid item xs sx={{ textAlign: 'center', color: 'var(--Blue-1, #2F80ED)' }}>
                      <Button
                        onClick={() => navigate(`/dashboard/candidate/jobDetails/${item.job_id}`)}
                        sx={{
                          color: 'inherit',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        {item.job_name} {`(${item.no_of_candidates})`}
                      </Button>
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => handleClick(item.pipeline_stage_stats.Associated_Screening, "Associated_Screening", item.job_id)}
                        sx={{
                          // color: 'inherit',
                          width:'60%',
                          '&:hover': {
                            backgroundColor: '#efefef',
                          },
                        }}
                        style={item.pipeline_stage_stats.Associated_Screening > 0 ? { Color: "#bed6f7" } : {color: 'inherit'}}
                      >
                        {item.pipeline_stage_stats.Associated_Screening}
                      </Button>
                    </Grid>
                    {/* <Grid item xs sx={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => handleClick(item.pipeline_stage_stats.Applied, "Applied", item.job_id)}
                        sx={{
                          color: 'inherit',
                          '&:hover': {
                            backgroundColor: '#efefef',
                          },
                        }}
                      >
                        {item.pipeline_stage_stats.Applied}
                      </Button>
                    </Grid> */}
                    <Grid item xs sx={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => handleClick(item.pipeline_stage_stats.Shortlisted, "Shortlisted", item.job_id)}
                        sx={{
                          // color: 'inherit',
                          width:'60%',
                          '&:hover': {
                            backgroundColor: '#efefef',
                          },
                        }}
                        style={item.pipeline_stage_stats.Shortlisted > 0 ? { Color: "#bed6f7" } : {color: 'inherit'}}
                      >
                        {item.pipeline_stage_stats.Shortlisted}
                      </Button>
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => handleClick(item.pipeline_stage_stats.Interview, "Interview", item.job_id)}
                        sx={{
                          // color: 'inherit',
                          width:'60%',
                          '&:hover': {
                            backgroundColor: '#efefef',
                          },
                        }}
                        style={item.pipeline_stage_stats.Interview > 0 ? { Color: "#bed6f7" } : {color: 'inherit'}}
                      >
                        {item.pipeline_stage_stats.Interview}
                      </Button>
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => handleClick(item.pipeline_stage_stats.Offered, "Offered", item.job_id)}
                        sx={{
                          // color: 'inherit',
                          width:'60%',
                          '&:hover': {
                            backgroundColor: '#efefef',
                          },
                        }}
                        style={item.pipeline_stage_stats.Offered > 0 ? { Color: "#bed6f7" } : {color: 'inherit'}}
                      >
                        {item.pipeline_stage_stats.Offered}
                      </Button>
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => handleClick(item.pipeline_stage_stats.Hired, "Hired", item.job_id)}
                        sx={{
                          // color: 'inherit',
                          width:'60%',
                          '&:hover': {
                            backgroundColor: '#efefef',
                          },
                        }}
                        style={item.pipeline_stage_stats.Hired > 0 ? { Color: "#bed6f7" } : {color: 'inherit'}}
                      >
                        {item.pipeline_stage_stats.Hired}
                      </Button>
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center' }}>
                      <Button
                        onClick={() => handleClick(item.pipeline_stage_stats.Onboarded, "Onboarded", item.job_id)}
                        sx={{
                          // color: 'inherit',
                          width:'60%',
                          '&:hover': {
                            backgroundColor: '#efefef',
                          },
                        }}
                        style={item.pipeline_stage_stats.Onboarded > 0 ? { Color: "#bed6f7" } : {color: 'inherit'}}
                      >
                        {item.pipeline_stage_stats.Onboarded}
                      </Button>
                    </Grid>
                  </Grid>
                ))}
                {(!data || !data.data || data.data.length === 0) && (
                  <Stack>
                    <Button variant='contained' sx={{ width: '200px', margin: '150px auto' }} onClick={()=>navigate('/dashboard/jobs/create-job')}>
                      Create New Job
                    </Button>
                  </Stack>
                )}
              </Stack>
            </div>
          </Stack>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8} mt={5}>
            <Card>
              <ChartsGraph />
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={4} mt={5}>
            <AppCurrentVisits
              title="Jobs Status"
              chartData={pipelineStagechartdata}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Jobs Application" 
              // subheader="(+43%) than last year"
              chartData={pipelineStageInterviewchartdata}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4} s>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Card sx={{
              minHeight: "100%",
            }}>
              <LatestInterview />
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  'New Job Posted',
                  '12 Candidates applied',
                  'Application closed',
                  '5 Shortlisted',
                  '1 candidate hired',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AgeofJob/>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Card sx={{
              minHeight: "100%",
            }}>
              <ToDoApp candidateId={candidateId}/>
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={8}>
            <Card sx={{
              minHeight: "100%",
            }}>
              <LatestInterview />
            </Card>
          </Grid> */}
        </Grid>
      </Container >
    </Page >
  );
}
